<template>
<div class="crud" v-loading.fullscreen.lock="loginAs||isLoading" :element-loading-text="loginAsMsg">
  <the-crud :title="title" :store="store" :columns="columns" :filters="filters" :masive-creation-columns="masiveCreationColumns" :search="search" :can-create="isAdmin" :can-edit="isAdmin" :can-delete="isAdmin">
    <template v-slot:action-buttons="scope">
      <el-dropdown-item icon="fas fa-search" @click.native="searchAgent(scope.row)">{{ $t('configuration.users.search-agent') }}</el-dropdown-item>
        <el-dropdown-item v-if="isAdmin" icon="fas fa-comment" @click.native="showConfirmationModal(scope.row)">{{ $t('configuration.users.clean-chats') }}</el-dropdown-item>
        <el-dropdown-item icon="fas fa-key" @click.native="modalChangePassword = true; user = scope.row">{{ $t('configuration.users.change-password') }}</el-dropdown-item>
        <el-dropdown-item v-if="isAdmin" :icon="scope.row.isActive == 1 ? 'fas fa-trash' : 'fas fa-trash-restore'" @click.native="handleActive(scope.row, (scope.row.isActive ? 0 : 1) )">
          {{ scope.row.isActive == 1 ? $t('configuration.users.desactivate') : $t('configuration.users.activate') }}
        </el-dropdown-item>
        <el-dropdown-item v-if="isAdmin" icon="fas fa-trash" @click.native="handleDeleteProfilePicture(scope.row)">{{ $t('configuration.users.delete-profile-picture') }}</el-dropdown-item>
        <el-dropdown-item v-if="isAdmin" icon="fas fa-sign-in-alt" @click.native="handleLoginAs(scope.row)">{{ $t('configuration.users.login-as-user', {
          name: scope.row.name + " " + scope.row.lastname
        }) }}</el-dropdown-item>
        <el-dropdown-item v-if="isAdmin || isManager" icon="fas fa-envelope" @click.native="handlePasswordRecovery(scope.row)">{{ $t('configuration.users.change-password-by-user') }}</el-dropdown-item>
    </template>
  </the-crud>
  <el-dialog
      :title="(user) ? $t('change-password.change-password-of', { name : `${user.name} ${user.lastname}` }) : ''"
      :visible.sync="modalChangePassword"
      :before-close="handleClose"
      :close-on-click-modal="closeModal"
      :close-on-press-escape="closeModal"
      :show-close="closeModal"
      width="50%">
      <the-change-password :user="user" ref="changePassword" @changingPassword="updatingPassword"></the-change-password>
    </el-dialog>
    <the-confirmation-modal
      :loading="deletingChats"
      :message="confirmationMessage" 
      :confirmation-value="confirmationValue" 
      :callback="deleteChats" 
      :confirm-button-text="$t('common.delete')" 
      confirm-button-type='danger' 
      ref="confirmationModal"
    />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheCrud from '@/components/crud/TheCrud'
import TheChangePassword from '@/components/account/TheChangePassword'
import TheConfirmationModal from '@/components/common/TheConfirmationModal'
import UserInfo from './user-info'
import authApi from '@/api/auth.js'

export default {
  components : {
    TheCrud,
    TheChangePassword,
    TheConfirmationModal,
  },
  data() {
    return {
      closeModal : true,
      user : null,
      modalChangePassword : false,
      confirmationValue: '',
      confirmationMessage: '',
      deletingChats: false,
      deleteChatFromUser: null,
      title : this.$t("configuration.users.title"),
      store : 'users',
      columns : UserInfo,
      filters : [
        { type : 'text', index : 'organizations.name', label : this.$t('configuration.users.organizations'), options : ['contains']},
        { type : 'boolean', index : 'isActive', label : this.$t('configuration.users.is-active'),  booleanType: "numeric"},
      ],
      search : [{ index : 'simplesearch', properties : ['name', 'lastname', 'email'] }],
      masiveCreationColumns : [
        { title :'name', description : this.$t("masive-creation.users.name"),type : 'string',example : 'John'},
        { title :'lastname', description : this.$t("masive-creation.users.lastname"),type : 'string',example : 'doe'},
        { title :'email', description : this.$t("masive-creation.users.email"),type : 'string',example : 'john.doe@example.com'},
        { title :'password', description : this.$t("masive-creation.users.password"),type : 'Q1t5w2e3r4#',example : ''},
        { title :'roles', description : this.$t("masive-creation.users.roles"),type : 'array',example : 'ROLE_USER|ROLE_AGENT'},
        { title :'organizations', description : this.$t("masive-creation.users.organizations"),type : 'array',example : '1'},
        { title :'extension', description : this.$t("masive-creation.users.extension"),type : 'array',example : '500'},
        { title :'hasVideoCall', description : this.$t("masive-creation.users.hasVideoCall"),type : 'yes|no',example : 'yes'},
        { title :'organization_sessions', description : this.$t("masive-creation.users.organization_sessions"),type : 'number',example : '1'},
        { title :'organization_theme',description : this.$t("masive-creation.users.organization_theme"),type : 'number',example : '1'}
      ],
      loginAs: false,
      loginAsUser: null,
      isLoading: false
    }
  },
  computed : {
    ...mapGetters(['isAdmin', 'isManager']),
    loginAsMsg() {
      if(this.isLoading) return this.$t('common.loading-please-wait')

      if (!this.loginAsUser) return "";
    
      return this.$t("configuration.users.login-as", {
        name: this.loginAsUser.name + " " + this.loginAsUser.lastname
      })
    }
  },
  methods : {
    handleClose() {
      this.$refs.changePassword.reset()
      this.modalChangePassword = false
    },
    updatingPassword(value) {
      this.closeModal = !value
    },
    showConfirmationModal(user) {
      this.confirmationMessage = this.$t('configuration.users.you-want-to-delete-the-user-chats', {name: user.name + ' ' + user.lastname}),
      this.confirmationValue = user.uid;
      this.deleteChatFromUser = user;
      this.$refs.confirmationModal.toggleShow();
    },
    async handleDeleteProfilePicture(user) {
      const message = this.$t("configuration.users.do-you-wanna-delete-the-profile-picture-from", { name : user.name + ' ' + user.lastname })
      try {
        const response = await this.$confirm(message, this.$t("common.delete") ,{ confirmButtonText: this.$t("common.yes"), cancelButtonText: this.$t("common.no"), type: 'warning' })
        if (response !== 'cancel') {
          await this.$store.dispatch('users/deleteProfilePictureFromUser', user.id)
          this.$message({
            message: this.$t('configuration.users.profile-picture-deleted', { name : user.name + ' ' + user.lastname }),
            type: 'success'
          });
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteChats() {
      this.deletingChats = true;
      try {
        await this.$store.dispatch('users/deleteChatsFromUser', this.deleteChatFromUser.id)
        this.$message({
          message: this.$t('chats-deleted', {name: this.deleteChatFromUser.name+ ' ' + this.deleteChatFromUser.lastname}),
          type: 'success'
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.confirmationValue = '';
        this.deleteChatFromUser = null;
        this.deletingChats = false;
        this.$refs.confirmationModal.toggleShow();
      }
    },
    async handleActive(row, active) {
      const msg = active ? 'configuration.users.are-you-sure-that-you-want-to-activate-the-user' : 'configuration.users.are-you-sure-that-you-want-to-delete-the-user'
      const message = this.$t(msg, { name :  row.name +" "+ row.lastname});
      this.$confirm(message, this.$t("common.delete") ,{
        confirmButtonText: this.$t("common.yes"),
        cancelButtonText: this.$t("common.no"),
        type: 'warning'
      }).then(async () => {
        this.loading = true;
        try {
          await this.$store.dispatch('users/toggleUserActive', { id : row.id, active })
          this.loading = false;
          this.$message({
            type: 'success',
            message: active ? this.$t("configuration.users.user-activate") : this.$t("configuration.users.user-desactivate")
          })
        } catch (error) {
          const title = this.$t("configuration.users.cant-delete.title")
          let index = 'firebase'

          if (error.response.status !== 500) {
            index = error.response.data.index;
          }

          const message = this.$t(`configuration.users.cant-delete.${index}`)

          this.$alert(message, title , {
            showConfirmButton : false,
          })
          this.loading = false;
        }
      }).catch(() => {
        console.log('cancel confirmation of active user')
      });
    },
    async handleLoginAs(row) {
      this.loginAs = true;
      this.loginAsUser = row;
      await this.$store.dispatch('loginAsUser', row.email);
      window.location.href = "/home";
    },
    searchAgent(row) {
      this.$router.push({
        name: "agent",
        query: {
          ["user.email"]: row.email
        }
      })
    },
    async handlePasswordRecovery(row){
      try{
        this.isLoading = true;
        await authApi.forgotPassword(row.email)
        this.$message({
          type: 'success',
          message: this.$t('recover-password.we-have-sent-to-your-email-the-instructions-to-be-able-to-recover-your-password')
        })
      }catch(e){
        this.$message({
          type: 'alert',
          message: this.$t('notification-abm.there-has-an-error')
        })
      }finally{
        this.isLoading = false;
      }
    }
  }
}
</script>