<template>
  <Box :title="$t('agent-console.chat.internalNotes')">
    <template v-slot:dropdown-right>
      <div class="internal-notes__actions-buttons-container">
        <el-button icon="fas fa-sticky-note" size="small" type="text" @click="isShowInternalNote=true">{{
          $t("agent-console.chat.administration-internal-notes")
        }}</el-button>
      </div>
    </template>
    <div class="internal-notes__notes-container">
      <div
        class="internal-notes__note-container"
        v-for="internalNote in internalNotesShort"
        :key="internalNote.id"
      >
        <div class="internal-notes__note-header">
          <span class="internal-notes__note-title">{{
            internalNote.title
          }}</span>
        </div>
        <div class="internal-notes__note-body">
          <p class="internal-notes__note-body-text">
            {{ internalNote.payload.text }}
          </p>
        </div>
        <div class="internal-notes__note-footer">
          <p class="internal-notes__note-footer-text">
            {{ internalNote.createdAt }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="internalNotes.length > 3" class="internal-notes__actions-buttons-container">
      <el-button size="small" @click="isShowInternalNote=true" type="text">{{ $t('agent-console.chat.show-all-internal-notes') }}</el-button>
    </div>
    <TheInternalNoteDialog :roomId="roomId" :internalNotes="internalNotes" :internalNoteId="selectedInternalNoteId" :isShowExternal="isShowInternalNote" @close="closeInternalNoteDialog"></TheInternalNoteDialog>
</Box>
</template>

<script>
import Box from "./Box.vue";
import TheInternalNoteDialog from './TheInternalNoteDialog';
export default {
  components: {
    Box,
    TheInternalNoteDialog,
  },
  props: {
    roomId: {
      required: true,
      type: String,
    },
  },
  data(){
    return{
      isShowInternalNote: false,
      selectedInternalNoteId: null
    }
  },
  computed: {
    internalNotes() {
      console.log(
        this.$store.getters["_chats/getChatByRoomId"](this.roomId).internalNotes
      );
      let notes =
        this.$store.getters["_chats/getChatByRoomId"](this.roomId)
          .internalNotes ?? [];

      if (notes.length > 0) {
        notes = notes.map((element) => {
          return { ...element, createdAt: this.formatDate(element.createdAt) };
        });
        notes = notes.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          return -1;
        });
        console.log(notes);
      }
      return notes;
    },
    internalNotesShort(){
      return this.internalNotes.slice(0, 3);
    }
  },
  methods: {
    async addInternalNote() {
      console.log(this.internalNotes);
      await this.$store.dispatch("doAddInternalNote", { roomId: this.roomId });
    },
    formatDate(date) {
      if (!date || !date.seconds || !date.nanoseconds) {
        return "";
      }

      const nanoseconds = date.nanoseconds;
      const seconds = date.seconds;
      const milliseconds = nanoseconds / 1e6;

      // Crear la fecha a partir de seconds y nanoseconds
      const formattedDate = new Date(seconds * 1000 + milliseconds);
      const now = new Date();

      // Calcular la diferencia de tiempo
      const timeDiff = now.getTime() - formattedDate.getTime();
      const oneDay = 24 * 60 * 60 * 1000; // Milisegundos en un día

      let formattedString;

      // Verificar si es hoy
      if (now.toDateString() === formattedDate.toDateString()) {
        const hours = String(formattedDate.getHours()).padStart(2, "0");
        const minutes = String(formattedDate.getMinutes()).padStart(2, "0");
        formattedString = `hoy, ${hours}:${minutes}`;
      }
      // Verificar si es ayer
      else if (
        timeDiff < oneDay * 2 &&
        now.getDate() - formattedDate.getDate() === 1
      ) {
        const hours = String(formattedDate.getHours()).padStart(2, "0");
        const minutes = String(formattedDate.getMinutes()).padStart(2, "0");
        formattedString = `ayer, ${hours}:${minutes}`;
      }
      // Si es antes de ayer
      else {
        const day = formattedDate.getDate();
        const month = formattedDate.toLocaleString("default", {
          month: "short",
        }); // mes en formato abreviado
        const hours = String(formattedDate.getHours()).padStart(2, "0");
        const minutes = String(formattedDate.getMinutes()).padStart(2, "0");
        formattedString = `${day} ${month}, ${hours}:${minutes}`;
      }

      return formattedString;
    },
    closeInternalNoteDialog(){
      this.isShowInternalNote = false;
      this.selectedInternalNoteId = null;
    },
  },
};
</script>
<style>
.internal-notes__actions-buttons-container .el-button{
  font-size: 12px;
  color: #409EFF !important;
}
.internal-notes__actions-buttons-container .el-button span:hover{
  color: #409EFF;
}
.internal-notes__actions-buttons-container .el-button .fas{
  margin-right: 2px;
}
</style>
<style scoped>
.internal-notes__notes-container {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  display: flex;
  flex-direction: column;
  gap: var(--column);
}
.internal-notes__note-container {
  background: #fbf9f9;
  border-radius: 5px;
  height: 100px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}
.internal-notes__actions-buttons-container {
  display: flex;
  gap: var(--column);
}
.internal-notes__note-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.internal-notes__note-body {
  height: 80%;
}
.internal-notes__note-body-text {
  font-size: 13px;
}
.internal-notes__note-footer {
  height: 20%;
  max-height: 20px;
}
.internal-notes__note-footer-text {
  font-size: 12px;
}
</style>
