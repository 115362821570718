import http from './http';

export default {
	fetchMediaServersData() {
		return http.get(`/media_servers?pagination=false`);
	},
	fetchMediaServers(id) {
		return http.get(`/media_servers/` + id);
	},
	createMediaServers(data) {
		return http.post(`/media_servers`, data);
	},
	updateMediaServers(data) {
		return http.put(`/media_servers/` + data.id, data);
	},
	deleteMediaServers(id) {
		return http.delete(`/media_servers/` + id);
	},
	saveAgentSession(server, sessionId) {
		return http.post(`/media_servers/sessions/agent`, {
			server, sessionId
		});
	}
};
