<template>
  <el-dialog width="70%" top="5%" destroy-on-close custom-class="internal-notes-dialog" :lock-scroll="false" :visible.sync="isShowInternal" :title="$t('agent-console.chat.administration-internal-notes')" @closed="$emit('close')">
    <TheInternalNoteDialogBody :roomId="roomId" :internalNotes="internalNotes" :internalNoteId="internalNoteId"></TheInternalNoteDialogBody>
  </el-dialog>  
</template>
<script>
import TheInternalNoteDialogBody from './TheInternalNoteDialogBody'
export default {
  components:{
    TheInternalNoteDialogBody
  },
  props: {
    isShowExternal: {
      required: true,
    },
    internalNotes: {
      required: true,
    },
    internalNoteId: {
      required: false,
      type: Boolean,
    },
    roomId: {
      required: true
    }
  },
  data(){
    return {
      isShowInternal: false,
      form: {
        title: '',
        text: ''
      }
    }
  },
  watch: {
    isShowExternal(){
      this.isShowInternal = this.isShowExternal
    }
  }
}
</script>

<style>
.internal-notes-dialog.el-dialog{
  margin-bottom: 0;
}

</style>