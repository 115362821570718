import { render, staticRenderFns } from "./TheInternalNoteDialogBody.vue?vue&type=template&id=336a3137&scoped=true&"
import script from "./TheInternalNoteDialogBody.vue?vue&type=script&lang=js&"
export * from "./TheInternalNoteDialogBody.vue?vue&type=script&lang=js&"
import style0 from "./TheInternalNoteDialogBody.vue?vue&type=style&index=0&id=336a3137&prod&lang=css&"
import style1 from "./TheInternalNoteDialogBody.vue?vue&type=style&index=1&id=336a3137&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336a3137",
  null
  
)

export default component.exports