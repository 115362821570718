<template>
  <el-drawer direction="rtl"
    :title="typeTransfer === 'call' ? $t('agent-console.transfer.transfer-call') : $t('agent-console.transfer.transfer-chat')"
    :visible.sync="drawer" :close-on-press-escape="!transfering" :show-close="!transfering"
    :wrapperClosable="!transfering" @closed="drawerClosed">
    <div class="agent-console-drawer">
      <div v-if="showContent">
        <el-form label-width="500px" label-position="top" :model="formSearchUser">
          <el-form-item
            :label="typeTransfer === 'call' ? $t('agent-console.transfer.search-for-the-user-or-write-the-number-to-transfer-the-call-to') : $t('agent-console.transfer.search-the-user-where-to-transfer-the-chat')">
            <el-input v-model="formSearchUser.name" ref="search" @keydown.enter.native.prevent
              @keydown.space.native.prevent></el-input>
          </el-form-item>
        </el-form>
        <div v-if="formSearchUser.name !== ''">
          <el-button v-if="typeTransfer == 'call'"
            @click.native=" blindTransfer({ title: formSearchUser.name, destination: formSearchUser.name, type: 'phone', date: new Date().getTime() })">
            {{ $t("agent-console.transfer.make-transfer-to", { name: formSearchUser.name }) }}
          </el-button>
          <el-button v-if="typeTransfer == 'call'"
            @click.native=" assistedTransfer({ title: formSearchUser.name, destination: formSearchUser.name, type: 'phone', date: new Date().getTime() })">
            {{ $t("agent-console.transfer.make-assisted-transfer-to", { name: formSearchUser.name }) }}
          </el-button>
        </div>
        <div class="recents" v-if="list.length > 0">
          <ul>
            <li v-for="(item, key) in list" :key="key" :class="{ active: item.selected } " class="item-list"
              @click="selectItemToTransfer(item)">
              <ListItem :item="item" />
              <i class="fas fa-coffee" v-if="!item.available_for_chats && item.type === 'agent' && typeTransfer === 'chat'" style="display:block;color:#F56C6C"></i>
            </li>
          </ul>
        </div>
        <div v-if="multipleChoices">
          <p>Seleccione a donde quiere hacer la transferencia del contacto {{ toTransfer.title }}</p>
          <div>
            <ul v-for="(item, index) in toTransfer.destination" :key="index">
              <el-radio v-model="multipleTransferValue" :label="item.value">{{ item.label }} {{ item.value }}</el-radio>
            </ul>
          </div>
        </div>
        <div class="buttons" v-if="list.length > 0">
          <el-button type="primary" v-if="toTransfer.title !== '' && typeTransfer == 'call'" @click="blindTransfer(null)"
            :disabled="multipleChoices && !multipleTransferValue">{{ $t("agent-console.transfer.direct-transfer")
            }}</el-button>
          <el-button type="primary" v-if="toTransfer.title !== '' && typeTransfer == 'call'"
            @click="assistedTransfer(null)" :disabled="multipleChoices && !multipleTransferValue">{{
              $t("agent-console.transfer.assisted-transfer") }}</el-button>
          <el-button type="primary" v-if="toTransfer.destination !== '' && typeTransfer == 'chat'"
            @click="chatTransfer(null)" :disabled="!toTransfer.destination">{{ $t("agent-console.transfer.transfer-chat")
            }} </el-button>
        </div>
      </div>
      <div class="middle">
        <div v-if="transfering">
          <p v-if="typeTransfer === 'call'">{{ $t("agent-console.transfer.transferring-call-to") }}</p>
          <p v-else>{{ $t("agent-console.transfer.transferring-chat-to") }}</p>
          <ListItem v-if="toTransfer.title" :item="toTransfer" />
          <p v-if="!inCallWithAttended">{{ $t("agent-console.please-wait") }}</p>
          <p v-else> {{ $t("agent-console.transfer.you-are-on-call-with", { name: toTransfer.title }) }}</p>
          <el-button v-if="inCallWithAttended" @click.native="hangupAndTransfer">{{
            $t("agent-console.transfer.to-transfer") }}</el-button>
          <the-go-back-to-call-button :janus="janus" v-if="typeTransfer === 'call'"
            @click.native="goBackToCall"></the-go-back-to-call-button>
        </div>

        <div v-if="success">
          <img src="@/img/success.svg" class="img-message mb-5" />
          <p v-if="typeTransfer === 'call'">{{ $t("agent-console.transfer.the-call-was-transferred-to") }}</p>
          <p v-else>{{ $t("agent-console.transfer.the-chat-was-transferred-to") }}</p>
          <p v-if="toTransfer.title">{{ toTransfer.title }}</p>
          <p v-else>{{ toTransfer }}</p>
          <el-button @click.native="drawer = false">{{ $t("common.close") }}</el-button>
        </div>

        <div v-if="error && toTransfer.title">
          <div v-if="!caller_hangup">
            <p>{{ $t("agent-console.transfer.the-call-could-not-be-transferred-because-has-dropped-the-call", {
              name:
                toTransfer.title
            }) }}</p>
            <el-button @click.native="goBackToCall">{{ $t("common.go-back") }}</el-button>
          </div>
          <div v-else>
            <p>{{ $t("agent-console.transfer.the-client-hangup", { name: toTransfer.title }) }}</p>
            <el-button @click.native="drawer = false">{{ $t("common.close") }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListItem from "@/components/common/ListItem";
import TheGoBackToCallButton from '@/components/webrtc/TheGoBackToCallButton'
export default {
  name: "TransferDrawer",
  data() {
    return {
      drawer: false,
      formSearchUser: {
        name: "",
      },
      toTransfer: {
        title: "",
        destination: "",
      },
      transfering: false,
      success: false,
      error: false,
      showContent: true,
      inCallWithAttended: false,
      typeTransfer: "chat",
      data: {},
      recentTransfers: this.getRecentlyTransferred(),
      caller_hangup: false,
      multipleTransferValue: "",
    };
  },
  props: {
    janus: {
      required: true,
    },
  },
  components: {
    ListItem,
    TheGoBackToCallButton
  },
  methods: {
    ...mapActions([
      "removeAction",
      "doChatTransfer",
      "listenToAction",
      "doBlindTransfer",
      "doAssistedTransfer",
      "fetchAgentsWitChat",
      "addRecentlyTransferred",
      "setCallTransfered",
    ]),
    ...mapGetters(["getRecentlyTransferred", "getAgentsDashboard"]),
    selectItemToTransfer(item, type = "search") {
      if (!item.selected) {
        if (type === "search") {
          this.list.forEach((recent, key) => {

            if (recent.uid === item.uid) {
              this.list[key].selected = true;
            } else {
              this.list[key].selected = false;
            }
          });
          this.toTransfer = {
            title: item.title,
            destination: this.typeTransfer === "chat" ? item.destination_chat : item.destination_call,
            type: item.type,
          };
        }
      } else {
        this.toTransfer = {
          title: "",
          number: "",
          type: "",
        };
        let index = 0;
        this.list.forEach((recent, key) => {
          if (recent.uid === item.uid) {
            index = key;
          }
        });
        this.list[index].selected = false;
      }
    },
    hangupAndTransfer() {
      this.janus.hangup();
      this.setCallTransfered();
    },
    blindTransfer(destination = null) {
      this.transfering = true;
      this.showContent = false;
      if (destination) {
        this.toTransfer = destination;
      }

      if (this.multipleTransferValue) {
        this.toTransfer.destination = this.multipleTransferValue
      }

      const transfer = typeof this.toTransfer.destination === 'string' ? this.toTransfer.destination.replace(/[A-Za-z$#%&/()=?¡¿!'\\|°"^[\]{}~;,.:\-_*+¬ `]/g, '') : this.toTransfer.destination
      this.janus.sendDtmf(`##${transfer}`)
      this.updateTransferStatus('', { status: 'success' });
      this.setCallTransfered();
    },
    assistedTransfer(destination = null) {
      this.transfering = true;
      this.showContent = false;

      if (destination) {
        this.toTransfer = destination;
      }

      if (this.multipleTransferValue) {
        this.toTransfer.destination = this.multipleTransferValue
      }

      const transfer = typeof this.toTransfer.destination === 'string' ? this.toTransfer.destination.replace(/[A-Za-z$#%&/()=?¡¿!'\\|°"^[\]{}~;,.:\-_*+¬ `]/g, '') : this.toTransfer.destination
      this.janus.sendDtmf(`*02${transfer}`);
      this.doAssistedTransfer(this.toTransfer.destination).then((snap) => {
        this.listenToAction({
          path: "call_center",
          actionId: snap.key,
          callback: (data) => {
            if (data) {
              if (data.status) {
                this.inCallWithAttended = false;
                this.updateTransferStatus(snap.key, data, 'call_center');
              }

              if (data.inCallWithAttended) {
                this.inCallWithAttended = true;
              }
            }
          },
        });
      });
    },
    chatTransfer(destination) {
      this.transfering = true;
      this.showContent = false;
      if (destination) {
        this.toTransfer = destination;
      }

      const transferType = this.toTransfer.type === 'user' ? "agent" : this.toTransfer.type
      this.doChatTransfer({ destination: this.toTransfer.destination, roomId: this.data.roomId, transferType }).then((snap) => {
        if (this.$store.getters.isUsingNewChatEngine) {
          this.$store.dispatch("agentConsoleChat/listenToChatEngineAction", {
            key: snap.key,
            callback: (data) => {
              if (data) {
                if (data.status) {
                  this.updateTransferStatus(snap.key, data, 'chat_center')
                }
              }
            }
          });
        } else {
          this.listenToAction({
            path: 'chat_center',
            actionId: snap.key,
            callback: (data) => {
              if (data) {
                if (data.status) {
                  this.updateTransferStatus(snap.key, data, 'chat_center');
                }
              }
            }
          })
        }
      })
      this.$store.dispatch('updateChatActivityItem', {roomId: this.roomId, status: 'finish', active: false})
    },
    updateTransferStatus(key, data, path) {
      if (!this.drawer) {
        return;
      }
      this.transfering = false;
      if (data.status === 'success') {
        this.success = true;
        this.addRecentlyTransferred(this.toTransfer);
      }

      if (data.status === 'error') {
        this.error = true;
        this.caller_hangup = (data.caller_hangup) ? true : false
      }

      if (key && data) {
        this.removeAction({ actionId: key, path: path });
      }

    },
    drawerClosed() {
      this.toTransfer = {
        title: "",
        number: "",
      };
      this.transfering = false;
      this.success = false;
      this.error = false;
      this.showContent = true;
      this.formSearchUser = {
        name: "",
      };
      this.recentTransfers.forEach((recent, key) => {
        this.recentTransfers[key].selected = false;
      });
      this.list.forEach((recent, key) => {
        this.list[key].selected = false;
      });
      this.inCallWithAttended = false;
      this.caller_hangup = false
      this.multipleTransferValue = ""
    },
    goBackToCall() {
      this.janus.sendDtmf('**')
      this.drawerClosed()
    },
    close() {
      this.drawer = false;
    }
  },
  computed: {
    ...mapGetters({ user: 'getUser' }),
    ...mapGetters("contacts", { tmpcontacts: "getAllContacts" }),
    ...mapGetters("bots", { bots: "getData" }),
    ...mapGetters("departments", { departments: "getData" }),
	...mapGetters({tmpqueues: "getQueuesDashboardList"}),
    multipleChoices() {
      return this.toTransfer.title && this.typeTransfer == 'call' && typeof this.toTransfer.destination !== 'string' && typeof this.toTransfer.destination !== 'number'
    },
    agent() {
      return this.$store.getters.getAgent
    },
    queues(){
      
      return this.tmpqueues.map( queue => {
        return {
          uid: queue.queue,
          name: queue.name,
          number: queue.queue,
          onlineAgents: queue.onlineAgents,
          totalAgentsAvaliables: queue.totalAgentsAvaliables,
          isQueueAgent: queue.isQueueAgent,
          answers: ['call'],
          destination_call: queue.queue,
          selected: false,
          type: 'queue'
        }
      })
       
    },
    contacts() {
      return this.tmpcontacts
        .filter(contact => contact.type !== "HiperMe" || contact.status !== "disconnected" )
        .filter(contact => contact.phones.length > 0)
        .map((contact) => {
          let destination_call = contact.phones;
          if (contact.type === "HiperMe") {
            destination_call = contact.visibleExtension;
          }

          return {
            uid: contact['@id'],
            title: contact.visiblename,
            selected: false,
            type: "user",
            answers: ["call"],
            destination_chat: '',
            destination_call: destination_call,
            call_avaliable: true,
            chat_avaliable: false
          }
        });
    },
    list() {
      const reg = new RegExp(this.formSearchUser.name, "ig");
      const contacts = this.contacts
      const agents = this.tmpAgentsLogged.map((agent) => {
        const index = contacts.findIndex(contact => contact.uid == agent.uid)
        if (index > -1) {
          const contact = contacts[index]
          if (typeof contact.destination_call === 'string') {
            contacts[index].destination_call = [
              { label: "Extension", value: contact.destination_call },
              { label: "Agent", value: agent.destination_call }
            ]
          }
        } else {
          return agent;
        }
      }).filter(agent => agent)

      const queueaux = this.queues.map((queue) => {
        
         if(!queue.isQueueAgent && queue.number){
            
              return {
                uid: queue.uid,
                title: `Cola ${queue.name}`,
                value: queue.number,
                answers: queue.answers,
                destination_call: queue.destination_call,
                selected: queue.selected,
                type: queue.type
              } 
            
         }
         return {
          title: null,
          value: null,
          answers: queue.answers
         }
       }).filter((element) => {
        return element.title !== null
       })

      const data = [...contacts, ...agents, ...queueaux]

      if (this.typeTransfer === "chat") {
        data.push(...this.bots.map(bot => {
          return {
            uid: bot.id,
            title: `${bot.name}`,
            selected: false,
            type: "bot",
            answers: ['chat'],
            destination_chat: bot.id,
            destination_call: null,
            call_avaliable: false,
            chat_avaliable: true
          }
        }));

        data.push(...this.departments.map(department => {
          return {
            uid: department.id,
            title: `${department.name}`,
            selected: false,
            type: "department",
            answers: ['chat'],
            destination_chat: department.id,
            destination_call: null,
            call_avaliable: false,
            chat_avaliable: true
          }
        }))
      }

      return data.filter((item) => {
        if (item.answers.includes(this.typeTransfer)) {
          if (reg.test(item.title) || this.formSearchUser.name === '') {
            return item;
          }
        }
      })
    },
    tmpAgentsLogged() {
      return this.getAgentsDashboard().map((agent) => {
        const answers = [];

        let available_for_chats = true
        let chat_avaliable = true;
        let call_avaliable = true
        let destination_chat = '';
        let destination_call = '';

        if (agent.call_logged) {
          answers.push('call');
          destination_call = agent.number;
          call_avaliable = agent.call_logged && !agent.in_break && agent.call_status !== 'call';
        }

        if (agent.chat_logged) {
          answers.push('chat');
          chat_avaliable = agent.chat_logged;
          destination_chat = agent.chat_center_id
          available_for_chats = 'available_for_chats' in agent ? agent.available_for_chats : true
        }

        return {
          uid: agent.uid,
          number: agent.number,
          title: `Agente ${agent.name}`,
          // title: `Agente ${agent.number}`,
          selected: false,
          type: "agent",
          answers: answers,
          destination_chat: destination_chat,
          destination_call: destination_call,
          call_avaliable: call_avaliable,
          chat_avaliable: chat_avaliable,
          available_for_chats: available_for_chats
        };
      }).filter((agent) => {
        if (this.typeTransfer === "chat") { 
          return agent.chat_avaliable
        }
        return agent.call_avaliable
      })
      //.filter((agent) => (agent.answers.includes('call') && agent.call_avaliable) || (agent.answers.includes('chat') && agent.chat_avaliable)).filter(a => a.uid !== this.user.uid)
    }
  },
  watch: {
    "drawer": function (value) {
      if (value) {
        setTimeout(() => {
          this.$refs.search.focus();
        }, 100)
      }
    }
  },
  created() {
    this.$store.dispatch('bots/all', { ["organization.id"]: this.agent.organization_id });
  }
};
</script>

<style scoped>
.agent-console-drawer {
  display: flex;
  color: var(--blue-grey);
  flex-direction: column;
  height: 97%;
  padding: 0 var(--column);
}

.agent-console-drawer .el-form-item__label {
  color: var(--blue-grey);
  line-height: inherit;
}

.agent-console-drawer .buttons {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}

.agent-console-drawer .buttons .el-button--primary {
  margin: var(--column) 0 0;
}

.agent-console-drawer ul {
  list-style: none;
}

.agent-console-drawer ul li {
  padding: var(--column);
  cursor: context-menu;
}

.agent-console-drawer ul li.active {
  background-color: var(--light-blue);
  border-radius: var(--radius);
}

.agent-console-drawer .middle {
  position: relative;
  text-align: center;
  margin: auto;
}

.agent-console-drawer .middle .item {
  flex-direction: column;
  margin-bottom: var(--column);
}

.agent-console-drawer .middle .success {
  margin: 0 auto;
  margin-bottom: var(--column);
}

.agent-console-drawer .middle .item .box {
  height: 3em;
  line-height: 3em;
  width: 3em;
}

.agent-console-drawer .middle .item .title {
  margin-top: var(--column);
  margin-left: 0;
}

.not-avaliable-agents {
  text-align: center;
}

.item-list {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
</style>