import i18n from '@/i18n'
import countries from '@/helpers/countries-with-flag.json'
import http from '../../api/http'

const getDefaultCustomerForm = () => {
  return [
    { key: 'name', label: i18n.t("common.name"), type: 'text', order: 1, editable: true, isRequired: true, deletable: false, searchable: true },
    { key: 'lastname', label: i18n.t("common.lastname"), type: 'text', order: 2, editable: true, isRequired: true, deletable: false,  },
    { key: 'phone', label: i18n.t("common.phone"), type: 'phone', order: 3, editable: true, isRequired: true, deletable: false, searchable: true },
    { key: 'wa_phone', label: i18n.t("common.whatsapp_phone"), type: 'phone', order: 3, editable: true, isRequired: false, deletable: false, searchable: true },
    { key: 'email', label: i18n.t("common.email"), type: 'email', order: 4, editable: true, isRequired: false, deletable: false, searchable: true },
    { key: 'business', label: i18n.t("customer.properties.business"), type: 'text', order: 5, editable: true, isRequired: false, deletable: false },
    { key: 'department', label: i18n.t("customer.properties.department"), type: 'text', order: 6, editable: true, isRequired: false, deletable: false },
    { key: 'country', label: i18n.t("customer.properties.country"), type: 'select', order: 7, editable: true, isRequired: false, deletable: false, options: countries.map(c => ({ label: c.name, image: c.image, value: c.code }))},
  ]
}

const state = {
  customerForm: getDefaultCustomerForm()
}

const getters = {
  getCustomerForm: state => state.customerForm,
  getTypesOfProperties: () => ([
    { icon: 'fas fa-font', label: i18n.t("customer.properties-types.text"), value: 'text' },
    { icon: 'fas fa-phone-alt', label: i18n.t("customer.properties-types.phone"), value: 'phone' },
    { icon: 'fas fa-at', label: i18n.t("customer.properties-types.email"), value: 'email' },
    { icon: 'fas fa-list', label: i18n.t("customer.properties-types.email"), value: 'select' }
  ]),
  getFieldFromFormByKey: state => key => state.customerForm.find(input => input.key === key)
}

const actions = {
  async fetchCustomerById( { rootGetters }, { organizationId, customerId}) {
    const firebase = rootGetters.getFirebase
    const snap = await firebase.firestore().collection('organizations').doc(organizationId).collection('customers').doc(customerId).get()
    if (snap.exists) {
      return { id: snap.id, ...snap.data()}
    }
    return null
  },
  async fetchCustomerByPhone( { rootGetters }, { organizationId, phone }) {
    const firebase = rootGetters.getFirebase
    const snap = await firebase.firestore().collection('organizations').doc(organizationId).collection('customers').where('search', 'array-contains', phone).get()
    if (!snap.empty) {
      const item = snap.docs[0]
      return { id: item.id, ...item.data()}
    }
    return null
  },
  fetchCustomerByKey( context, { organizationId, key, value, page = 1 }) {
    return http.get('/customers', {
      params: {
        v: 2,
        organization: organizationId,
        property: key,
        value,
        page
      }
    })
  },
  addCustomer(context, { organization, customer }) {
   return http.post('/customers', {...customer, organization }, {
    params: {
      organization
    }
   })
  },
  addPropertyToCustomer(context, { organizationId, customer, payload }) {
    const { id } = customer
    return http.post(`/customers/${id}/property`, payload, {
      params : {
        organization: organizationId
      }
    })
  },
  async editPropertyToCustomer(context, { organizationId, customer, payload }) {
    const { id } = customer
    return http.post(`/customers/${id}/property`, payload, {
      params : {
        organization: organizationId
      }
    })
  },
  async deletePropertyOfCustomer({ rootGetters }, { organizationId, customer, key }) {
    const firebase = rootGetters.getFirebase
    const values = customer.values.filter(property => property.key !== key) 
    const search = values.filter(property => property.searchable && property.value !== '').map(property => property.value)
    const { id } = customer
    await firebase.firestore().collection('organizations').doc(organizationId).collection('customers').doc(id).update({
      values,
      search,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
  },
  async fetchCustomProperties({ rootGetters }, { organizationId }) {
    const firebase = rootGetters.getFirebase
    const snapshot = await firebase.firestore().collection('organizations').doc(organizationId).collection('customersCustomProperties').get()
    
    if (snapshot.empty) {
      return []
    }

    return snapshot.docs.map((doc) => {
      const { label, key } = doc.data()
      return { label, key }
    })
  },
  addChatToCustomer(context, { customerId, chatId, organization }) {
    return http.post(`/customers/${customerId}/chats`, {
      chatId
    }, {
      params: {
        organization
      }
    })
  }
}

export default {
  namespaced : true,
  state,
  actions,
  getters
}