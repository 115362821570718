import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from './i18n/index';
import * as Formated from './filters/format'
import * as Dates from './filters/dates'
import VueApexCharts from 'vue-apexcharts'
import { Auth0Plugin } from '@/auth'
import * as Sentry from "@sentry/vue";
import VueGtm from '@gtm-support/vue2-gtm';
import { RecycleScroller } from 'vue-virtual-scroller'

Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value)
});

if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_VERSION) {
	Sentry.init({
		Vue,
		release: process.env.VUE_APP_VERSION,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 0.1, // Reduce el muestreo en producción
		tracePropagationTargets: [/^https:\/\/[a-zA-Z0-9-]+\.hiperpbx\.com/, "localhost"],
		replaysSessionSampleRate: 0.05,
		replaysOnErrorSampleRate: 0.5,
		beforeSend: (event, hint) => {
			const exception = hint.originalException
			if (exception.name === 'NavigationDuplicated') {
				return null
			}

			return event
		}
	});
} else {
	console.warn('Sentry no está configurado correctamente.');
}

if (process.env.VUE_APP_GTM_ID) {
	Vue.use(VueGtm, {
		id: process.env.VUE_APP_GTM_ID,
		defer: false,
		enabled: true,
		debug: false,
		loadScript: true,
		vueRouter: router,
		trackOnNextTick: false,
	});
}

Vue.use(VueApexCharts)

const { domain, clientId } = {
	domain : process.env.VUE_APP_DOMAIN,
	clientId : process.env.VUE_APP_CLIENTID,
}

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
})

Vue.component('apexchart', VueApexCharts)
Vue.component('RecycleScroller', RecycleScroller)

Vue.config.productionTip = false;

Vue.filter('utf8', Formated.decode)
Vue.filter('calendar', Dates.calendar)
Vue.filter('chat', Dates.chatRedeableDate)
Vue.filter('hhmmss', Dates.secondsToFormat)
Vue.filter('redeableDate', Dates.redeableDate)
Vue.filter('firstLetter', Formated.firstLetter)
Vue.filter('usersWriting', Formated.usersWriting)
Vue.filter('hhmm', Dates.secondsToHourMinutes)
Vue.filter('date', Dates.secondsToDate)
Vue.filter('ticketDate', Dates.ticketDate)

new Vue({
	i18n,
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
