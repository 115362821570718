<template>
  <div class="crud form main-view">
    <el-form :model="model" :rules="rules" size="small" ref="form" v-loading="loading"
    :element-loading-text="loadingText" v-if="status === null" target="#app-main-content" label-position="top">
      <h2>{{ title }} </h2>
      <h3>{{ $t('contact-me.chat-center.basic-settings') }} </h3>

      <el-form-item :label="$t('configuration.users.name')" required prop="name">
        <el-input v-model="model.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('contact-me.chat-center.provider-type')" required prop="chatProvider">
        <el-select v-model="model.chatProvider">
          <el-option v-for="option in chatsProviders" :key="option.id" :label="option.name"
            :value="option.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('contact-me.chat-center.language')" required >
        <el-select v-model="model.lang">
          <el-option v-for="option in languages" :key="option.value" :label="option.label"
            :value="option.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Whatsapp">
        <el-input v-model="model.whatsappNumber"></el-input>
      </el-form-item>

      <el-form-item :label="$t('contact-me.chat-center.strategy')">
        <el-select v-model="model.strategy">
          <el-option v-for="option in strategies" :key="option.value" :label="option.label"
            :value="option.value"></el-option>
        </el-select>
      </el-form-item>


      <h3>{{ $t('contact-me.chat-center.bot-settings') }}</h3>
      <el-form-item label="Bot">
        <el-select v-model="model.bot">
          <el-option label="None" :value="null"></el-option>
          <el-option v-for="option in bots" :key="option.id" :label="option.name" :value="option.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Bot auto start" v-if="model.bot !== null">
        <el-switch v-model="model.botAutoStart"></el-switch>
      </el-form-item>

      <h3>{{ $t('contact-me.chat-center.message-settings') }}</h3>
      <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">

        <el-form-item :label="$t('contact-me.chat-center.show-multimedia')">
          <el-switch v-model="model.showImageFromClient"></el-switch>
        </el-form-item>

        <el-form-item :label="$t('contact-me.chat-center.show-agent-name-in-system-messages')">
          <el-switch v-model="model.showAgentName"></el-switch>
        </el-form-item>

        <el-form-item :label="$t('contact-me.chat-center.send-system-messages-to-client')">
          <el-switch v-model="model.enableSysMsgClient"></el-switch>
        </el-form-item>
      </div>

      <div>
        <h3 style="margin-bottom: 8px">{{ $t('contact-me.chat-center.client-timeout-message-settings') }} </h3>
        <p style="font-size:14px">{{ $t('contact-me.chat-center.client-timeout-message-settings-description') }}</p>
        <el-form-item :label="$t('contact-me.chat-center.client-timeout')">
          <el-input type="number" v-model.number="model.clientTimeout"></el-input>
        </el-form-item>
        <el-form-item :label="$t('contact-me.chat-center.client-inactivity-message')" v-if="model.clientTimeout > 0">
          <el-input type="textarea" v-model="model.clientInactivityMessage"></el-input>
        </el-form-item>
      </div>

      <div>
        <h3 style="margin-bottom: 8px">{{ $t('contact-me.chat-center.automatic-response-settings') }} </h3>
        <p style="font-size:14px">{{ $t('contact-me.chat-center.automatic-response-settings-description') }}</p>
        <el-form-item :label="$t('contact-me.chat-center.response-timeout')" prop="chatActivityTimeout">
          <el-input type="number" v-model.number="model.chatActivityTimeout"></el-input>
        </el-form-item>

        <el-form-item :label="$t('contact-me.chat-center.action-to-do-if-timeout')"
          v-if="model.chatActivityTimeout > 0">
          <el-select v-model="model.timeoutAction">
            <el-option v-for="option in timeoutActions" :key="option.value" :label="option.label"
              :value="option.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('contact-me.chat-center.transfer_to')"
          v-if="model.chatActivityTimeout > 0 && (model.timeoutAction === 'transfer_to_bot' || model.timeoutAction === 'transfer_to_department')">
          <el-select v-model="model.timeoutTransferId">
            <el-option v-for="option in timeoutTransferTo" :key="option.id" :label="option.name"
              :value="option.id"></el-option>
          </el-select>
        </el-form-item>



      </div>

      <div class="buttons">
        <el-button size="small" @click="$router.go(-1)">{{ $t("common.cancel") }}</el-button>
        <el-button type="primary" size="small" @click="save">{{ $t("common.save") }}</el-button>
      </div>

    </el-form>
    <div class="message" v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 200" />
        <img src="@/img/error.svg" v-else />
        <p> {{ message }} </p>
        <div class="buttons">
          <el-button type="text" @click="$router.go(-1)">{{ $t("common.go-back-to-the-list") }}</el-button>
          <el-button type="text" @click="status = null" v-if="status !== 404">{{ $t("common.show-the-form")
            }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },

  data () {

    const checkTimeout = (rule, value, callback) => {
      if (value > 0 && value < 60) {
        callback(new Error(this.$t('contact-me.chat-center.the-value-must-be-0-or-greater-than-or-equal-to-60')));
      } else {
        callback()
      }
    }

    return {
      model: {
        name: this.entity ? this.entity.name : "",
        whatsappNumber: this.entity ? this.entity.whatsappNumber : "",
        lang: this.entity ? this.entity.lang : "en",
        chatProvider: this.entity ? this.entity.chatProvider.id : "",
        bot: this.entity && this.entity.bot ? this.entity.bot.id : "",
        strategy: this.entity ? this.entity.strategy : "",
        chatActivityTimeout: this.entity ? this.entity.chatActivityTimeout : 0,
        botAutoStart: this.entity ? Boolean(this.entity.botAutoStart) : 0,
        showAgentName: this.entity ? Boolean(this.entity.showAgentName) : 1,
        enableSysMsgClient: this.entity ? Boolean(this.entity.enableSysMsgClient) : 0,
        showImageFromClient: this.entity ? Boolean(this.entity.showImageFromClient) : 1,
        clientTimeout: this.entity ? this.entity.clientTimeout : 0,
        clientInactivityMessage: this.entity ? this.entity.clientInactivityMessage : "",
        timeoutAction: this.entity ? this.entity.timeoutAction : null,
        timeoutTransferTo: this.entity ? this.entity.timeoutTransferTo : null,
        timeoutTransferId: this.entity ? this.entity.timeoutTransferId : 0,
      },
      rules: {
        name: [
          { required: true, message: this.$t("contact-me.chat-center.name-is-required"), trigger: 'blur' },
        ],
        chatProvider: [
          { required: true, message: this.$t("contact-me.chat-center.chat-provider-is-required"), trigger: 'blur' },
        ],
        chatActivityTimeout: [
          { validator: checkTimeout, trigger: 'blur' }
        ]
      },
      loading: false,
      status: null,

      languages: [
        { label: this.$t("languages.es"), value: "es" },
        { label: this.$t("languages.en"), value: "en" }
      ],
      strategies: [
        { label: this.$t("strategy.secuencial"), value: 'secuencial' },
      ],
      timeoutActions: [
        { label: this.$t("contact-me.chat-center.set_to_pending"), value: "set_to_pending" },
        { label: this.$t("contact-me.chat-center.transfer_to_bot"), value: "transfer_to_bot" },
        { label: this.$t("contact-me.chat-center.transfer_to_department"), value: "transfer_to_department" },
      ] 
    }
  },
  computed: {
    ...mapGetters({
      chatsProviders: 'chatProviders/getData',
      bots: 'bots/getData',
      departments: 'departments/getData',
    }),
    timeoutTransferTo () {
      if (this.model.timeoutAction === 'transfer_to_bot') {
        return this.bots
      }

      if (this.model.timeoutAction === 'transfer_to_department') {
        return this.departments
      }

      return []
    },
    organization () {
      return this.$store.getters["contactMe/getOrganizationId"]
    },
    message () {
      if (this.status === 200) {
        return this.$t("common.saved")
      }

      if (this.status === 404) {
        return this.$t("common.not-found")
      }

      return this.$t("common.not-saved")
    },
    loadingText () {
      return this.$t('common.saving')
    },
    title () {
      return this.id !== "0" ? this.$t("contact-me.chat-center.edit-department") : this.$t("contact-me.chat-center.new-department")
    }
  },
  methods: {
    save () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          return false;
        }

        const model = { ...this.model }
        model.chatProvider = `/api/chat_providers/${this.model.chatProvider}`
        model.bot = this.model.bot ? `/api/bots/${this.model.bot}` : null
        model.organization = `/api/organizations/${this.organization}`

        model.enableSysMsgClient = this.model.enableSysMsgClient ? 1 : 0
        model.botAutoStart = this.model.botAutoStart ? 1 : 0
        model.showAgentName = this.model.showAgentName ? 1 : 0
        model.showImageFromClient = this.model.showImageFromClient ? 1 : 0

        model.timeoutTransferTo = null;

        if (model.timeoutAction === 'transfer_to_bot') {
          model.timeoutTransferTo = 'bot';
        }

        if (model.timeoutAction === 'transfer_to_department') {
          model.timeoutTransferTo = 'department';
        }

        this.loading = true
        try {
          if (this.id !== "0") {
            await this.$store.dispatch('departments/update', { id: this.id, payload: model })
          } else {
            await this.$store.dispatch('departments/create', model)
          }
          this.status = 200
        } catch {
          this.status = 400
        } finally {
          this.loading = false
        }
      })

    }
  },
  created () {
    this.$store.dispatch('chatProviders/all', { ['organization.id']: this.organization })
    this.$store.dispatch('bots/all', { ['organization.id']: this.organization })
    this.$store.dispatch('departments/all', { ['organization.id']: this.organization })
  }
}
</script>

<style scoped>
.message {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.message>div:first-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>