<template>
  <el-dialog id="export-chat-modal" v-loading.fullscreen.lock="fullscreenLoading" :visible.sync="internalShow" width="30%" @closed="closeModal" :title="$t('agent-console.chat.export-chat.title')">
    <div class="export-chat-modal__container">
      <el-button class="export-chat-modal__option-buttons" @click="exportAsPdf" type="primary">{{ $t('agent-console.chat.export-chat.export-as-pdf') }}</el-button>
      <el-button class="export-chat-modal__option-buttons" @click="exportAsText" type="primary">{{ $t('agent-console.chat.export-chat.export-as-txt') }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import html2pdf from "html2pdf.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    roomId: {
      require: true
    }
  },
  data(){
    return {
      internalShow: false,
      fullscreenLoading: false,
    
    }
  },
  computed: {
    agent(){
            return this.$store.getters['getAgent']
    },
    getActiveItem(){
        return this.$store.getters['getActiveItem']
    },
    messages() {
        return (this.$store.getters['_chats/getChatByRoomId'](this.roomId)) ? this.$store.getters['_chats/getChatByRoomId'](this.roomId).messages : []
    },
  },
  methods: {
    async exportAsPdf(){

        this.fullscreenLoading = true
        const doc = html2pdf()
        const chatTest = document.getElementById('chat-test').cloneNode(true);
        chatTest.querySelectorAll('#export-chat-modal')[0].remove()
        const mediaElements = chatTest.querySelectorAll('img, video, audio');
        const clientInfoBox = document.getElementById('clientInfoBox').cloneNode(true);
        const systemMessagesBox = document.getElementById('system-messages').cloneNode(true);

        mediaElements.forEach(function(element) {
            const src = element.src || element.querySelector('source')?.src;
            if (src) {
                var link = document.createElement('a');
                link.href = src;
                link.target = "_blank";
                
                
                link.style.position = 'relative';
                link.style.zIndex = '1';
                
                if (element.tagName === 'VIDEO' || element.tagName === 'AUDIO') {
                    
                    const text = document.createElement('span');
                    text.textContent = element.tagName === 'VIDEO' ? 'Video' : 'Audio';
                    link.style.fontSize = '20px';
                    const parentElement = element.closest('.chat-message-data');
                    const parentElement2 = element.closest('.message');

                    if(parentElement && parentElement2){
                        parentElement2.style.height = '25px'
                        parentElement2.style.width = '60px'
                        
                        parentElement.style.display = 'flex'
                        parentElement.style.flexDirection = 'column'
                        parentElement.style.justifyContent = 'flex-end'

                        parentElement.style.alignItems = 'flex-end'
                        parentElement2.style.display = 'flex'
                        parentElement2.style.alignItems = 'flex-start'
                    }
                    element.parentNode.insertBefore(link, element);
                    link.appendChild(text);
                }else{
                    link.style.display = 'block';

                    link.style.width = 'fit-content';
                    link.style.height = 'fit-content';
                    console.log(element)
                    element.parentNode.insertBefore(link, element);
                    link.appendChild(element);
                }
                
            }
        });

        const container = document.createElement('div');

        container.style.pageBreakInside = 'avoid';

        container.appendChild(chatTest)
        container.appendChild(clientInfoBox)
        container.appendChild(systemMessagesBox)
        container.style.display = 'flex'
        container.style.flexDirection = 'column'
        container.style.gap = '100px'
        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5],
            filename: 'Chat.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2,useCORS: true, allowTaint: true, letterRendering: true, logging: false},
            jsPDF: { unit: 'in', format: 'a4', orientation: 'p', autoPaging: 'text'},
            enableLinks: true,
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }

        }
        await doc.from(container).set(opt).save();
        this.fullscreenLoading = false;
    },
    exportAsText(){
      this.fullscreenLoading = true;
      let textContent = `${this.getActiveItem.title ?? ''} - answered by ${this.agent.name}\n\n`
    
        this.messages.forEach((element) => {
            const date = new Date(element.date)
            let sender = ''
            console.log(this.getActiveItem)

            if(element.user_id === 0){
                const clientInfo = this.getActiveItem.clientinfo.find((element) => element.id === 'nick')
                sender = clientInfo ? clientInfo.value : '';
            }
            else if(element.user_id === 3){
                sender = this.agent.name ?? ''
            }else {
                sender = 'System'
            }
            const messageDetails = `[${date.toLocaleDateString()} ${date.toLocaleTimeString()}][${sender}]`
            if(element.type == 'text'){
                textContent += `${messageDetails} ${element.payload.text}\n\n`;
            }else if(element.type == 'location'){
                textContent += `${messageDetails} https://www.google.com/maps?q=${element.payload.longitude},${element.payload.latitude}\n\n`;
            }else{
                textContent += `${messageDetails} ${element.payload.url}\n\n`;

            }
        })
        
        setTimeout(()=>{
          
          this.fullscreenLoading = false;
          const blob = new Blob([textContent], { type: 'text/plain' });
          const link = document.createElement('a');
          
          link.href = URL.createObjectURL(blob);
          link.download = 'Chat.txt';
          
          document.body.appendChild(link);
          link.click();
          
          document.body.removeChild(link);
        }, 1000)
        
      },
    closeModal(){
      this.$emit('closeModal')
    }
  },
  watch: {
    show (newValue) {
      this.internalShow = newValue;
    }
  }
}
</script>
<style scoped>
.export-chat-modal__container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.export-chat-modal__option-buttons{
  width: 100%;
  margin: 0 !important;
}
</style>