import ServerBase from '@/views/servers/Server.vue'
import ServersFirebaseRoutes from './servers_firebase'
import ServersMediaServerRoutes from './servers_mediaserver'
import ServersOsticketsRoutes from './servers_ostickets'
import ServersLiveHelperChatRoutes from './servers_live_helper_chat'

const routes = [
    {
      name: "Servers",
      path: "/configuration/servers",
      component: ServerBase,
      children: [
        ...ServersFirebaseRoutes,
        ...ServersOsticketsRoutes,
        ...ServersMediaServerRoutes,
        ...ServersLiveHelperChatRoutes
      ],
      meta : {
        isInMenu : true,
        type: "title",
        title : 'main-menu.configuration.servers.title',
        access_control : ['ROLE_ADMIN'],
        requiresAuth : true
      },

    },

]

export default routes;