import Janus from "./janus";
import makeid from './tab-count'
import * as Sentry from '@sentry/browser';
import { io } from "socket.io-client";

function shuffle(array) {
    var currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

function NoCaptureDevice(message) {
    this.message = message;
    this.name = 'NoCaptureDevice';
}

function UnexpectedAnswer(message) {
    this.message= message;
    this.name = 'UnexpectedAnswer';
}

function InvalidHandle(message) {
    this.message= message;
    this.name = 'InvalidHandle';
}

function JanusProxy(_config) {

    let _janus, sipPluginHandle, JanusSipElement,  textroomPluginHandle;

    let sipMessage;

    let _callingTo, _pbxdomain, _callingToNumber;

    let registered = false;

    let isInCall = false;

    let callingError;

    let onErrorAttachSip;

    let onDestroyedSession;

    let inputDevice = 'default'

    let replaceInputDevice = null

    let registerTimeout;

    let myStream;

    let offerlessInvite = false;
    
    let uniqueid;

    let call_id = "";

    let ping_to = null;
    let ping_times = {};
    let transactions = {};
    let rx_ping_times = {};
    const ping_interval = 6000;
    const myid = Janus.randomString(12);
    const myusername = Janus.randomString(12);
    const myroom = new Date().getTime() % 10000000;
    let total_ping_time, pings_sent, pings_received, total_jitter, last_ping_time = 0;
    let receivedPingData = null;
    let subscribeInterval;

    let pcmWorkerRemote, pcmWorkerLocal;

    let socketRemote, socketLocal;

    let callbackSocketRemote = _config.callbackSocketRemote ?? null;
    let callbackSocketLocal = _config.callbackSocketLocal ?? null;
    let organizationId = _config.organizationId ?? 0

    var startConnection = function () {
        return new Promise(function (resolve, reject) {
            Janus.init({
                debug: false,
                callback: function () {
                    _janus = new Janus({
                        server: shuffle(_config.server),
                        apisecret: _config.api_secret,
                        /* iceServers: _config.stunServer ? [{
                            urls: _config.stunServer
                        }] : null, */
                        success: function () {
                                setTimeout(() => {
                                    attachSipPlugin();
                                }, 3000)
                                return resolve();
                        },
                        error: function (message) {
                            return reject(message);
                        },
                        destroyed: function (error) {
                            if (onDestroyedSession) {
                                onDestroyedSession(error)
                            }
                            if (error) {
                                console.error(error)
                            }
                        }
                    });
                }
            });
        });
    }

    var attachTextroomPlugin = function () {
        _janus.attach({
            plugin: 'janus.plugin.textroom',
            opaqueId: 'console-' + Janus.randomString(12),
            success: function (pluginHandle) {
                textroomPluginHandle = pluginHandle;
                textroomPluginHandle.send({ "message": { "request": "setup" } });
            },
            onmessage: function (msg, jsep) {
                if (jsep !== undefined && jsep !== null) {
                    textroomPluginHandle.createAnswer({
                        jsep: jsep,
                        media: { audio: false, video: false, data: true },
                        success: function (jsep) {
                            textroomPluginHandle.send({ "message": { "request": "ack" }, "jsep": jsep });
                        }
                    });
                }
            },
            ondataopen: function () {
                textroomPluginHandle.send({ "message": { "request": "exists", "room": myroom }, "success": replyExists, "error": (error) => {
                    console.log(error)
                } });
            },
            ondata: function (data) {
                var json = JSON.parse(data);
                var transaction = json["transaction"];
                if (transactions[transaction]) {
                    transactions[transaction](json);
                    delete transactions[transaction];
                    return;
                }

                var what = json["textroom"];
                if (what === "message") {
                    receivedPing(json["text"]);
                } else if (what === "destroyed") {
                    if (json["room"] !== myroom) {
                        return;
                    }
                }
            }
        });
    }

    var startPing = function (callback) {
        total_ping_time = 0;
        pings_sent = 0;
        pings_received = 0;
        total_jitter = 0;
        last_ping_time = 0;
        ping_times = {};
        ping_to = setInterval(sendPing, ping_interval);
        const interval = setInterval(() => { 
            if (!_janus.isConnected()) {
                if (callback) {
                    if (typeof callback === "function") {
                        callback(_janus.isConnected())
                    }
                }
                clearInterval(interval);
                if (receivedPingData) {
                    receivedPingData({status : false})
                }
            }
        }, 1000)

    }

    var stopPing = function () {
        clearInterval(ping_to);
        ping_to = null;
    }

    var sendPing = function () {
        if (!textroomPluginHandle) {
            return
        }

        const message = { textroom: "message", transaction: Janus.randomString(12), ack: false, room: myroom, text: "" + pings_sent };
        ping_times[pings_sent] = performance.now();
        pings_sent++;
        textroomPluginHandle.data({ 
            text: JSON.stringify(message),
            error : (error) => {
                console.log(error)
            }
         });
    }

    var receivedPing = function (msg) {
        var t2 = performance.now();
        rx_ping_times[pings_received] = t2;
        ++pings_received;
        var t1 = ping_times[msg];
        if (t1) {
            if (0 != last_ping_time) {
                total_jitter += Math.abs(last_ping_time - (t2 - t1));
                var avg_jitter = total_jitter / (pings_received - 1);
            }
            last_ping_time = t2 - t1;
            total_ping_time += last_ping_time;
        }

        var avg = total_ping_time / pings_received;
        var packet_loss = (pings_sent - pings_received) / pings_sent * 100;

        if (receivedPingData) {
            const avgJitter = (avg_jitter !== undefined) ? parseInt(avg_jitter.toFixed(0)) : 0
            receivedPingData({ 
                status : true, 
                pingsSents: pings_sent, 
                pingsReceived: pings_received, 
                packetLoss: packet_loss.toFixed(0), 
                averagePingTime: parseInt(avg.toFixed(0)), 
                avgJitter 
            })
        }
    }

    var replyExists = function (msg) {
        if (typeof (msg["exists"]) != 'undefined') {
            if (msg["exists"]) {
                registerUsername();
            } else {
                textroomPluginHandle.send({ "message": 
                { "request": "create", "room": myroom }, "success": replyCreate });
            }
        }
    }

    var registerUsername = function () {
        const transaction = Janus.randomString(12); 
        const register = { textroom: "join", transaction: transaction, room: myroom, username: myid, display: myusername };
        textroomPluginHandle.data({ text: JSON.stringify(register) });
    }

    var replyCreate = function () {
        registerUsername();
    }


    var attachSipPlugin = function () {
        _janus.attach({
            plugin: 'janus.plugin.sip',
            opaqueId: 'console-' + Janus.randomString(12),
            success: function (pluginHandle) {
                sipPluginHandle = pluginHandle;
                registerSipExtension();
                setTimetoutRegister()
            },
            error: function (error) {
                if (onErrorAttachSip) {
                    onErrorAttachSip(error)
                }
            },
            onremotestream: async (stream) => {
                if (JanusSipElement) {
                    Janus.attachMediaStream(JanusSipElement, stream);
                }
                        
                if (!callbackSocketRemote) {
                    return
                }

                while (!uniqueid) {
                    await new Promise(resolve => setTimeout(resolve, 500)); 
                }

                socketRemote = io(process.env.VUE_APP_SPEECH_TO_TEXT_SOCKET)
                socketRemote.connect();
                socketRemote.emit('speaker', {"speaker": "USER", uniqueid: uniqueid, region: process.env.VUE_APP_REGION, org: organizationId});

                const audioContext = new AudioContext({ sampleRate: 16000 });
                const source = audioContext.createMediaStreamSource(stream)
                await audioContext.audioWorklet.addModule('/pcmWorker.js')

                pcmWorkerRemote = new AudioWorkletNode(audioContext, 'pcm-worker', {
                    outputChannelCount: [1]
                })
                source.connect(pcmWorkerRemote)

                pcmWorkerRemote.port.onmessage = event => {
                    socketRemote.emit('audioStream', event.data)
                }

                socketRemote.on("transcripción", (transcription) => {
                    if (callbackSocketRemote) {
                        callbackSocketRemote(transcription)
                    }
                })

                pcmWorkerRemote.port.start()
            },
            onlocalstream: async (stream) => {
                myStream = stream;

                if (!callbackSocketLocal) {
                    return;
                }

                while (!uniqueid) {
                    await new Promise(resolve => setTimeout(resolve, 500)); 
                }

                socketLocal = io(process.env.VUE_APP_SPEECH_TO_TEXT_SOCKET);
                socketLocal.connect()
                socketLocal.emit('speaker', {"speaker": "AGENT", uniqueid: uniqueid, region: process.env.VUE_APP_REGION, org: organizationId});

                const audioContext = new AudioContext({ sampleRate: 16000 });
                const source = audioContext.createMediaStreamSource(stream)
                await audioContext.audioWorklet.addModule('/pcmWorker.js')

                pcmWorkerLocal = new AudioWorkletNode(audioContext, 'pcm-worker', {
                    outputChannelCount: [1]
                })
                source.connect(pcmWorkerLocal)
                
                pcmWorkerLocal.port.onmessage = event => {
                    socketLocal.emit('audioStream', event.data)
                }
                
                socketLocal.on("transcripción", (transcription) => {
                    if (callbackSocketLocal) {
                        callbackSocketLocal(transcription)
                    }
                })

                pcmWorkerLocal.port.start()
            },
            onmessage: function (message, jsep, json) {    
                if (message.result) {
                    if (message.result.event === 'registered') {
                        registered = true;
                        subscribeInterval = setInterval(() => {
                            sipPluginHandle.send({
                                message : {
                                    request : "subscribe",
                                    event : "message-summary"
                                },
                                error : () => {
                                    // console.log(e)
                                }
                            })
                        }, 30000)
                    }
    
                    if (message.result.event === 'registered_fail') {
                        registered = false;
                    }

    
                    if (message.result.event === 'progress') {
                        if (jsep !== null && jsep !== undefined) {
                            sipPluginHandle.handleRemoteJsep({
                                jsep: jsep, error: function () {
                                    console.log('Error')
                                }
                            });
                        }
                    }

                    if (message.result.event === 'incomingcall') {
                        offerlessInvite = false;

                        if (jsep) {
                            offerlessInvite = true;
                        }

                        const msg = {
                            sipPluginHandle: sipPluginHandle.getId(),
                            sender: json['sender'],
                            sipPluginSesion: _janus.getSessionId(),
                            session_id: json['session_id']
                        }

                        if (json['session_id'] !== _janus.getSessionId()) {
                            if (process.env.VUE_APP_SENTRY_DSN) {   
                                Sentry.captureMessage(JSON.stringify(msg));
                                console.log(json)
                            }
                        }
                    }

                    if (message.result.event === 'incomingcall') {
                        call_id = new Date().getTime() + "@" + makeid(10);
                    }
    
                    if (message.result.event === 'accepted') {
                        if (jsep !== null && jsep !== undefined) {
                            sipPluginHandle.handleRemoteJsep({
                                jsep: jsep, error: function (error) {
                                    console.log(error)
                                }
                            });
                        }
                        isInCall = true;
                    }
    
                    if (message.result.event === 'hangup') {
                        isInCall = false;
                        Janus.stopAllTracks(myStream);
                        if (pcmWorkerRemote) {
                            pcmWorkerRemote.port.close()
                        }
                        if (pcmWorkerLocal) {
                            pcmWorkerLocal.port.close()
                        }

                        if (socketRemote) {
                            socketRemote.close()
                        }

                        if (socketLocal) {
                            socketLocal.close()
                        }
                    }

                    if (message.result.event === "unregistered") {
                        clearTimeout(registerTimeout);
                        registerSipExtension();
                        setTimetoutRegister()
                    }
                }

                if (sipMessage) {
                    sipMessage(message, jsep);
                } 
            }
        });
    }

    var setTimetoutRegister = function() {
        registerTimeout = setTimeout(() => {
            registerSipExtension()
            setTimetoutRegister()
        }, 300000)
    }

    var registerSipExtension = function () {
        _pbxdomain = _config.extension.domain;

        if (!sipPluginHandle) {
            return;
        }

        sipPluginHandle.send({
            'message': {
                authuser: _config.extension.number.toString(),
                display_name: _config.extension.displayname,
                proxy: 'sip:' + _config.extension.domain,
                request: 'register',
                username: 'sip:' + _config.extension.number + '@' + _config.extension.domain,
                secret: _config.extension.secret,
            }
        })
    }

    var unregisterSipExtension = function() {
        if (sipPluginHandle) {
            sipPluginHandle.send({
                message: {
                    request: 'unregister'
                }
            })
        }
    }

    function call(extension, name) {
        return new Promise((resolve, reject) => {
            _callingTo = name
            _callingToNumber = extension;
            call_id = new Date().getTime() + "@" + makeid(10);

            extension = extension.replace(/[-_+ ()]/g,'') 
            
            if (extension.indexOf('@') < 0) {
                extension += '@' + _pbxdomain;
            }
            
            sipPluginHandle.createOffer({
                media: { audioSend: true, audioRecv: true, videoSend: false, videoRecv: false },
                success: function (_jsep) {
                    sipPluginHandle.send({ 'message': { request: 'call', uri: 'sip:' + extension, call_id: new Date().getTime() + "@" + makeid(10) }, 'jsep': _jsep });
                    // sipPluginHandle.send({ 'message': { request: 'call', uri: 'sip:' + extension }, 'jsep': _jsep });
                    return resolve(true);
                },
                error : function(error) {
                    if (callingError) {
                        callingError(error);
                    }
                    return reject(error);
                }
            });
        })
    }

    function getCallId() {
        return call_id;
    }

    function restartCapture() {
        sipPluginHandle.createOffer({
            media: { 
                audioSend: true, 
                audioRecv: true, 
                videoSend: false, 
                videoRecv: false, 
                replaceAudio : replaceInputDevice ,
                audio: {
					deviceId: {
						exact: inputDevice
					}
				}
            }
        })
    }

    function hangup() {
        sipPluginHandle.send({ 'message': { 'request': 'hangup' } });
        sipPluginHandle.hangup();
    }

    function decline() {
        sipPluginHandle.send({ 'message': { 'request': 'decline' } });
    }

    function answer(jsep) {
        return new Promise((resolve, reject) => {
            const sipcallAction = (offerlessInvite) ? sipPluginHandle.createAnswer : sipPluginHandle.createOffer;
             sipcallAction({
                 jsep,
                 media: { 
                     audioSend: true, 
                     audioRecv: true, 
                     video: false, 
                 },
                 success: function (jsep) {
                     setTimeout(() => {
                         sipPluginHandle.send({
                             message: { request: 'accept' }, 
                             jsep: jsep,
                             error: function(error) {
                                 if (process.env.VUE_APP_SENTRY_DSN) {
                                    Sentry.captureMessage(JSON.stringify({
                                        jsepType: jsep.type,
                                        callAction: offerlessInvite ? 'createOffer' : 'createAnswer'
                                    }));
                                     Sentry.captureException(error);
                                 }
 
                                 const reg = new RegExp('469 Unexpected ANSWER');

                                 if (reg.test(error)) {
                                    restart();
                                    return reject(new UnexpectedAnswer(error));
                                 }

                                 if (error == 'Invalid handle') {
                                    restart();
                                    return reject(new InvalidHandle(error));
                                 }
 
                                 return reject(error);
                             },
                             success: () => {
                                 
                                 return resolve(true);
                             }
                         });
                     }, 500)
                 },
                 error: function (e) {
                    if (e === "No capture device found") {
                        return reject(new NoCaptureDevice('No capture device found'));
                    }
                     
                     sipPluginHandle.send({ 'message': { request: 'decline', code : 480 }});
                     return reject(e);
                 }
             });
        });
    }

    function hold() {
        sipPluginHandle.send({ 'message': { 'request': 'hold' } });
    }

    function unhold() {
        sipPluginHandle.send({ 'message': { 'request': 'unhold' } });
    }

    function mute() {
        sipPluginHandle.muteAudio();
    }

    function unmute() {
        sipPluginHandle.unmuteAudio();
    }

    function sendDtmf(digit, position = 0) {
        if (digit.length < 2) {
            sipPluginHandle.send({ 'message': { 'request': 'dtmf_info', 'digit': digit } });
        } else {

            if (digit[position] === undefined) {
                return
            } 

            sipPluginHandle.send({
                message: { 
                    request: 'dtmf_info', 
                    digit: digit[position] 
                },
                success: () => {
                    setTimeout(() => {
                        const next = position + 1;
                        if (digit[next] !== undefined) {
                            sendDtmf(digit, next);
                        }
                    }, 500)
                },
                error: (e) => {
                    console.log(`Error al enviar el tono DTMF ${digit[position]}`, e);
                    sendDtmf(digit, position);
                }
             });
        }
    }

    function unregister() {
        if (sipPluginHandle) {
            sipPluginHandle.send({ message : {
                request : 'unregister'
            }})
        }
    }

    function restart() {
        /* sipPluginHandle.detach();
        clearTimeout(registerTimeout); */
        _janus.destroy({
            notifyDestroyed: true, 
            cleanupHandles: true,
            success: () => {
                stopPing();
                clearTimeout(registerTimeout);
                clearTimeout(subscribeInterval);
            }
        });
    }

    return {
        startConnection,
        onSipMessage: function (callback) {
            sipMessage = callback;
        },
        attachAudio: function (element) {
            JanusSipElement = element
        },
        getCallTo: function () {
            return _callingTo
        },
        getCallToNumber: function () {
            return _callingToNumber
        },
        getIsInCall: () => isInCall,
        randomId: function () {
            return Janus.randomString(12)
        },
        isRegistered: function () {
            return registered
        },
        initPing: function () {
            if (!textroomPluginHandle) {
                attachTextroomPlugin();
            }
        },
        /* onReceivedPing : function(callback) {
            receivedPingData = callback;
        } */
        call,
        hold,
        mute,
        unhold,
        unmute,
        answer,
        hangup,
        decline,
        sendDtmf,
        registerSipExtension,
        startPing,
        stopPing,
        unregister,
        getCallId,
        server : function() {
            return _janus.getServer()
        },
        onCallingError : (callback) => {
            callingError = callback;
        },
        onErrorAttachSip : (callback) => {
            onErrorAttachSip = callback
        },
        changeMicrophone : function(inputDeviceId) {
            if (!replaceInputDevice) {
                replaceInputDevice = inputDeviceId
            }
            replaceInputDevice = inputDeviceId !== inputDevice
            inputDevice = inputDeviceId
            restartCapture()
        },
        destroy : function(callback = null) {
            _janus.destroy({
                notifyDestroyed: true, 
                cleanupHandles: true, 
                success: () => {
                    stopPing();
                    clearTimeout(registerTimeout);
                    clearTimeout(subscribeInterval);
                    if (callback) {
                        callback()
                    }
                }
            });
        },
        onDestroyedSession : (callback) => {
            onDestroyedSession = callback
        },
        stopAllTracks: function() {
            Janus.stopAllTracks(myStream);
        },
        unregisterSipExtension,
        sessionId: () => _janus.getSessionId(),
        setUniqueId: (value) => uniqueid = value
    }
}



export default {
    init(server, api_secret, extension, stunServer, callbackSocketRemote = null, callbackSocketLocal = null, organizationId = 0) {
        return new JanusProxy({ server, api_secret, extension, stunServer, callbackSocketRemote, callbackSocketLocal, organizationId });
        
    },
    randomId() {
        return Janus.randomString(12)
    }
}
