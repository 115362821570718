<template>
  <el-dialog width="65%" :center="true" :visible.sync="isVisible" :close-on-click-modal="false"
    :destroy-on-close="true">
    <section class="customer-search" v-loading="isLoading">
      <h2>
        <el-button @click="selectedCustomer = null" type="text" v-if="selectedCustomer" icon="fas fa-arrow-left">{{
          $t('common.go-back') }}</el-button>{{ title }}
      </h2>
      <div class="customer-search__input-container" v-if="!selectedCustomer">
        <el-select v-model="property">
          <el-option v-for="property in properties" :key="property.key" :value="property.key" :label="property.label"
            class="customer-search__option">
            <span style="float: left">{{ property.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ property.key }}</span>
          </el-option>
        </el-select>
        <el-input :placeholder="$t('customer.search-customer-input-placeholder', { by: selectedProperty.label })" prefix-icon="el-icon-search"
          v-model="filteredValue" @keydown.native.enter="handleSearch">
        </el-input>
        <el-button @click="handleSearch" type="primary">{{ $t("common.search") }}</el-button>
      </div>
      <div v-if="noResults" class="customer-search__no-search-results">
        <p>{{ $t('common.no-search-results') }}</p>
        <p>{{ $t('common.we-couldnt-find-the-resource-you-were-looking-for') }}</p>
        <p>{{ $t('common.try-searching-again') }}</p>
      </div>
      <span :class="['customer-search__details-message', detailsMessage.type]">
        {{ detailsMessage.text }}
      </span>
      <div class="customer-search__info-rows-container" v-if="!selectedCustomer">
        <the-table :rows="customers" :columns="columns" v-if="customers.length > 0" :loading="false" :pagination="pagination" @change-page="changePage">
          <template v-slot:createdAt="scope">
            {{ scope.row.createdAt | redeableDate(scope.row.createdAt) }}
          </template>
          <template v-slot:actions="scope">
            <el-button type="text" @click.native="selectCustomer(scope.row.id)">View</el-button>
          </template>
        </the-table>
      </div>
      <div v-else>
        <TheClientBox class="customer-search-dialog__client-info-box" :client="selectedCustomer" :isStatic="true" />
      </div>
    </section>
  </el-dialog>
</template>

<script>
import TheClientBox from '../../components/agent-console/TheClientBox.vue'
import TheTable from '@/components/crud/TheTable'
export default {
  props: {
    isExternalVisible: {
      required: true
    }
  },
  components: {
    TheTable,
    TheClientBox
  },
  data () {
    return {
      property: 'name',
      properties: [
        {
          label: this.$t('common.name'),
          key: 'name'
        },
        {
          label: this.$t('common.phone'),
          key: 'phone'
        },
        {
          label: this.$t('common.email'),
          key: 'email'
        }
      ],
      columns: [
        {
          label: this.$t('common.name'),
          index: 'name'
        },
        {
          label: this.$t('common.lastname'),
          index: 'lastname'
        },
        {
          label: this.$t('common.phone'),
          index: 'phone'
        },
        {
          label: this.$t('common.createdAt'),
          index: 'createdAt'
        },
        {
          label: '',
          index: 'actions'
        }
      ],
      customers: [],
      filteredValue: '',
      selectedCustomer: null,
      isDialogVisible: false,
      isLoading: false,
      isVisible: false,
      detailsMessage: {
        type: '',
        text: ''
      },
      noResults: false,
      page: 1,
      pagination: {
        page : this.page,
        totalPages : 1, 
        totalItems : 0,
        next: null,
      }
    }
  },
  methods: {
    getLastPage(view) {
      return ( (view['hydra:last']) ? parseInt(view['hydra:last'].split('=').pop()) : 1 )
    },
    changePage({page}) {
      this.page = page
      this.handleSearch()
    },
    async handleSearch () {
      if (this.filteredValue.trim().length < 3) {
        return
      }

      this.noResults = false
      this.isLoading = true

      try {
        const { data } = await this.$store.dispatch("customers/fetchCustomerByKey", { organizationId: this.organizationId, key: this.property, value: this.filteredValue, page: this.page })
        this.customers = data['@hydra:member']
        this.noResults = this.customers.length === 0
        this.pagination = {
          page: this.page,
          totalPages : ('hydra:view' in data) ? this.getLastPage(data['hydra:view']) : 1, 
          totalItems : data['hydra:totalItems'],
          next: ('hydra:view' in data && 'hydra:next' in data["hydra:view"]) ? data["hydra:view"]["hydra:next"].replace("/api", "") : null,
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }

    },
    async selectCustomer (customerId) {
      console.log(customerId)
      this.selectedCustomer = await this.$store.dispatch("customers/fetchCustomerById", { organizationId: this.organizationId, customerId })
      this.isDialogVisible = true
    }
  },
  computed: {
    organizationId () {
      return this.$store.getters["getAgent"].organization_id.toString()
    },
    activeClient () {
      return this.$store.getters['getActiveItem']
    },
    isValidActivity () {
      return this.activeClient && this.activeClient.type === 'chat'
    },
    title () {
      return this.selectedCustomer ? this.selectedCustomer.values.filter(v => v.key === 'name' || v.key === 'lastname').map(v => v.value).join(' ') : this.$t("customer.search-customer")
    },
    selectedProperty() {
      return this.properties.find(property => this.property === property.key)
    }
  },
  watch: {
    isExternalVisible (newValue) {
      this.isVisible = newValue;
    },
    filteredValue() {
      this.page = 1
    },
    isVisible (newValue) {
      if (!newValue) {
        this.$emit('closeDialog')
      } else {
        this.$store.dispatch("customers/fetchCustomProperties", { organizationId: this.organizationId }).then((properties) => {
          this.properties = [
            ...new Map(
              [...this.properties, ...properties].map((property) => [property.key, property])
            ).values()
          ];
        })
      }
    }
  }
}
</script>

<style scoped>
section {
  max-height: 100vh;
  overflow: hidden;
  padding: 0 calc(var(--column) / 2);
}

.customer-search h2 {
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.customer-search__input-container {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.customer-search__info-rows-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 60vh;
  overflow-y: auto;
}

.customer-search__info-row {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #dcdfe6;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #606266;
  align-items: center;
  gap: 5px;
}

.customer-search__info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.customer-search__avatar-container {
  background-color: var(--light-green);
  border-radius: 50px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-search__avatar {
  font-size: 30px
}

.customer-search__details-message {
  font-weight: bold;
  font-size: 12px;
}

.customer-search__details-message.alert {
  color: red;
}

.customer-search__option span {
  display: block
}

.customer-search__no-search-results {
  display: flex;
  flex-direction: column;
  gap:6px;
  align-items: center;
}
</style>