<template>
  <div style="height: 60vh;" v-loading.fullscreen="isLoading">
    <div class="internal-notes-dialog-body__container">
      <div class="internal-notes-dialog-body__left-container">
        <div class="internal-notes-dialog-body__left-container-header">
          <el-input v-model="filteredValue" placeholder="Buscar"></el-input>
        </div>
        <div v-if="filteredNotes.length > 0" class="internal-notes-dialog-body__notes-container">
          <div
            :class="['internal-notes-dialog-body__note-container', {'selected-note': selectedInternalNote ? selectedInternalNote.id === internalNote.id : false}]"
            v-for="internalNote in filteredNotes"
            :key="internalNote.id"
            @click="selectNote(internalNote)"
          >
            <div class="internal-notes-dialog-body__note-header">
              <span class="internal-notes-dialog-body__note-title">{{
                internalNote.title
              }}</span>
            </div>
            <div class="internal-notes-dialog-body__note-body">
              <p class="internal-notes-dialog-body__note-body-text">
                {{ internalNote.payload.text }}
              </p>
            </div>
            <div class="internal-notes-dialog-body__note-footer">
              <p class="internal-notes-dialog-body__note-footer-text">
                {{ internalNote.createdAt }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class="internal-notes-dialog-body__nothing-notes-container">
          <span>No hay notas que mostrar</span>
        </div>
      </div>
      <div class="internal-notes-dialog-body__right-container">
        <div v-if="!selectedInternalNote && mode.view" class="internal-notes-dialog-body__image-container">
          <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
            <img class="internal-notes-dialog-body__image" src="@/img/undraw_notes_re_pxhw.svg">
          <span>{{ $t('agent-console.chat.internal-notes-image-text') }} <span @click="changeModeTo('create')" style="color: var(--blue-500); cursor: pointer;">{{ $t('common.here') }}</span></span>
          </div>
          
        </div>
        <div v-else>
          <div class="internal-notes-dialog-body__which-action">
            <span>{{ whichActionText }}</span>
          </div>
          <el-form  v-model="form">
            <el-form-item class="internal-notes-dialog-body__form-item" :label="$t('common.title')">
              <p v-if="selectedInternalNote && mode.view" class="internal-notes-dialog-body__form-item-message-fake-input">{{ selectedInternalNote?.title }}</p>
              <el-input v-if="mode.edit || mode.create" v-model="form.title" type="text"></el-input>
            </el-form-item>
            <el-form-item class="internal-notes-dialog-body__form-item" :label="$t('common.message')">
              <p v-if="selectedInternalNote && mode.view" class="internal-notes-dialog-body__form-item-message-fake-textarea">{{ selectedInternalNote?.payload.text }}</p>
              <el-input
                v-if="mode.edit || mode.create"
                class="internal-notes-dialog-body__form-item-message-input"
                v-model="form.text"
                type="textarea"
                resize="none"
                size="medium"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="internal-notes-dialog-body__document-actions-container">
            <div>
              <el-button v-if="mode.view && selectedInternalNote" @click="handleModeInternalNote" type="danger">{{ $t("common.delete") }}</el-button>
            </div>
            <div>
              <el-button v-if="mode.view" type="primary" @click="changeModeTo('create')">{{ $t("common.create") }}</el-button>
              <el-button v-if="mode.view" @click="changeModeTo('edit')">{{ $t("common.edit") }}</el-button>
              <el-button v-if="mode.edit | mode.create" @click="changeModeTo('view')">{{ $t("common.cancel") }}</el-button>
              <el-button v-if="mode.edit | mode.create" @click="handleModeInternalNote" type="primary">{{ $t("common.save") }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    internalNotes: {
      required: true,
    },
    internalNoteId: {
      required: false,
      type: Boolean,
    },
    roomId: {
      required: true
    }
  },
  data() {
    return {
      selectedInternalNote: null,
      form: {
        title: '',
        text: ''
      },
      mode: {
        edit: false,
        view: false,
        create: false
      },
      filteredValue: '',
      isLoading: false
    };
  },
  methods:{
    changeModeTo(value){
      const keys = Object.keys(this.mode)
      keys.forEach((element) => {
        if(element !== value){
          this.mode[element] = false
        }
      })
      this.mode[value] = true
      if(value === 'create'){
        this.form.text = ''
        this.form.title = ''
        this.selectedInternalNote = null
      }
    },
    selectNote(note){
      this.changeModeTo('view')
      this.selectedInternalNote = note
      console.log(this.selectedInternalNote)
      this.form.text = this.selectedInternalNote.payload.text
      this.form.title = this.selectedInternalNote.title
    },
    async handleModeInternalNote() {
      console.log(this.internalNotes);
      console.log(this.mode);
      try {
        if(this.mode.edit){
            this.isLoading = true;
            await this.$store.dispatch("doUpdateInternalNote", { roomId: this.roomId, noteId: this.selectedInternalNote.id,payload: this.form });  
            setTimeout(()=>{
              this.isLoading = false;
            }, 3000)   
        }else if(this.mode.create){
          this.isLoading = true;
          await this.$store.dispatch("doAddInternalNote", { roomId: this.roomId, payload: this.form });  
          setTimeout(()=>{
            this.isLoading = false;
            this.form.text = ''
            this.form.title = ''
          }, 3000)
        
        }else{
          this.isLoading = true;
          this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel'
        }).then(async () => {
          await this.$store.dispatch("doDeleteInternalNote", { roomId: this.roomId, noteId: this.selectedInternalNote.id });  
          setTimeout(()=>{
            this.isLoading = false;
            this.form.text = ''
            this.form.title = ''
            this.$message({
            type: 'success',
            message: this.$t('agent-console.chat.internal-note-deleted')
            });
            this.selectedInternalNote = null;
            this.changeModeTo('view');
          }, 3000)
        }).catch(() => {
          this.isLoading = false;
          this.$message({
            type: 'info',
            message: this.$t('agent-console.chat.internal-note-delete-cancel')
          });          
        });
        }            
      } catch (error) {
        this.isLoading = false;
      }
      
    },
  },
  computed: {
    whichActionText(){
      if(this.mode.create){
        return `Creando nueva nota`
      }
      if(this.mode.edit){
        return `Editando nota: ${this.selectedInternalNote.title}` 
      }
      return `Visualizando nota: ${this.selectedInternalNote.title}`
    },
    filteredNotes(){
      if(this.filteredValue.length > 4){
        return this.internalNotes.filter((element) => element.title.includes(this.filteredValue) || element.payload.text.includes(this.filteredValue))
      }
      return this.internalNotes
    }
  },
  watch:{
    internalNoteId(){
      if(this.internalNoteId){
        this.selectedInternalNote = this.internalNotes.find((element) => this.internalNoteId === element.id)
        console.log(this.selectedInternalNote)
      }
    }
  },
  created(){
    if(!this.internalNoteId){
      this.mode.view = true
    }else {
      this.mode.edit = true
    }
  }
};
</script>
<style>
.el-form-item__content .internal-notes-dialog-body__form-item-message-input .el-textarea__inner{
  min-height: 140px !important;
}
.internal-notes-dialog-body__form-item .el-form-item__label{
  float: none;
}
</style>
<style scoped>
.internal-notes-dialog-body__container {
  display: flex;
  gap: var(--column);
  height: 55vh;
  gap: var(--column);
}
.internal-notes-dialog-body__left-container {
  display: flex;
  flex-direction: column;
  gap: var(--column);
  width: 30%;
}
.internal-notes-dialog-body__notes-container p,
span {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.internal-notes-dialog-body__notes-container {
  display: flex;
  flex-direction: column;
  gap: var(--column);
  overflow-y: auto;
  padding-right: 5px;
}

.internal-notes-dialog-body__note-container {
  background: #fbf9f9;
  border-radius: 5px;
  height: 110px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
  cursor: pointer;
}
.internal-notes-dialog-body__note-container.selected-note{
  border: 1px solid var(--blue-300);
}
.internal-notes-dialog-body__note-header{
  height: auto;
  text-overflow: ellipsis;
}
.internal-notes-dialog-body__note-title {
  font-weight: bold;
  height: 100%;
  margin-bottom: 5px;
  /* Limitar a una sola línea con puntos suspensivos */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.internal-notes-dialog-body__note-body {
  height: 80%;
  overflow: hidden;
}
.internal-notes-dialog-body__note-body-text {
  font-size: 13px;
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.internal-notes-dialog-body__note-footer {
  height: 20%;
  max-height: 20px;
}
.internal-notes-dialog-body__note-footer-text {
  font-size: 12px;
}
.internal-notes-dialog-body__nothing-notes-container{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}


.internal-notes-dialog-body__right-container{
  display: flex;
  flex-direction: column;
  width: 70%;
  position: relative;
}
.internal-notes-dialog-body__image-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--column);
  font-size: 17px;
  height: 100%;
}
.internal-notes-dialog-body__image{
  width: 50%;
}
.internal-notes-dialog-body__which-action{
  font-size: 14px;
  font-weight: bold;
}
.internal-notes-dialog-body__form-item-message-fake-textarea{
  min-height: 140px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 5px 15px;
  line-height: 21px;
}
.internal-notes-dialog-body__form-item-message-fake-input{
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
}
.internal-notes-dialog-body__document-actions-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>

