import { parseMessageFromFirebase } from '@/helpers/chat'

const state = {
  chats: []
};

const getters = {
  getChats: (state) => state.chats,
  getChatsByPhone: (state) => (phone) => {
    console.log(state.chats)
    console.log(phone)
    console.log(state.chats.find(chat => chat.data.phone === phone && chat.data.status !== 2)); return state.chats.find(chat => chat.data.phone === phone && chat.data.status !== 2)},
  getChatByRoomId: (state) => (roomId) => (state.chats.find(chat => chat.roomId === roomId)),
};

const mutations = {
  PUSH_TO_CHATS: (state, chat) => (state.chats.push(chat)),
  ADD_MESSAGE_TO_CHAT(state, { roomId, message }) {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.messages.push(message);
    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  ADD_UNPARSED_MESSAGE_TO_CHAT(state, { roomId, unparsedMessage }) {
    const chat = state.chats.find(chat => chat.roomId === roomId);
    chat.unparsedMessages.unshift(unparsedMessage);
    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  ADD_PREVIUS_MESSAGE_TO_CHAT(state, { roomId, message }) {
    const chat = state.chats.find(chat => chat.roomId === roomId)

    if (!chat.messages.find(m => m.id === message.id)) {
      chat.messages.unshift(message);
    }

    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  RESET_UNREAD_MESAGE_TO_CHAT(state, roomId) {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.unreadMessages = 0;
    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  SUM_UNREAD_MESSAGE_TO_CHAT(state, roomId) {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.unreadMessages++;
    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  UPDATE_DATA(state, { roomId, data }) {
    const chat = state.chats.find(chat => chat.roomId === roomId);
    chat.data = data;
    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  UPDATE_UNSUBSCRIBE(state, { roomId, unsubscribe }) {
    const chat = state.chats.find(chat => chat.roomId === roomId);
    chat.unsubscribe = unsubscribe;
    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  RESET_MESSAGES_FROM_CHAT(state, roomId) {
    const chat = state.chats.find(chat => chat.roomId === roomId);
    chat.messages = [];
    state.chats = [...state.chats.filter(c => c.roomId !== chat.roomId), chat]
  },
  SET_LAST_MESSAGE(state, { roomId, message }) {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.lastMessage = message
    state.chats = [...state.chats.filter(c => c.roomId !== roomId), chat]
  },
  UPDATE_MESSAGE(state, { roomId, message } ) {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.messages = chat.messages.map((msg) => msg.id === message.id ? message : msg)
    state.chats = [...state.chats.filter(c => c.roomId !== roomId), chat]
  },
  SET_ANSWER_MESSAGE(state, { roomId, message }) {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.answerMessage = message
    state.chats = [...state.chats.filter(c => c.roomId !== roomId), chat]
  },
  UPDATE_UNREAD_MESSAGE: (state, {roomId, value}) => {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    if (chat) {
      chat.unreadMessages = value;
      state.chats = [...state.chats.filter(c => c.roomId !== roomId), chat]
    }
  },
  UPDATE_INTERNAL_NOTES: (state, {roomId, internalNote}) => {
    console.log(internalNote)
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.internalNotes = chat.internalNotes ?? []
    let localInternalNote = chat.internalNotes.find( item => item.id === internalNote.id)
    if(localInternalNote){
      localInternalNote = internalNote
      chat.internalNotes = [...chat.internalNotes.filter(c => c.id !== internalNote.id), localInternalNote] 
    }else{
      chat.internalNotes.push(internalNote)
    }
    state.chats = [...state.chats.filter(c => c.roomId !== roomId), chat]
  },
  REMOVE_INTERNAL_NOTE: (state, {roomId, noteId}) => {
    const chat = state.chats.find(chat => chat.roomId === roomId)
    chat.internalNotes = chat.internalNotes ?? []
    let localInternalNote = chat.internalNotes.find( item => item.id === noteId)
    if(localInternalNote){
      chat.internalNotes = [...chat.internalNotes.filter(c => c.id !== noteId)] 
    }
    state.chats = [...state.chats.filter(c => c.roomId !== roomId), chat]
  }
};

const actions = {
  initChatroom({ commit }, { name, path, roomId, myself, version = 0 }) {
    commit('PUSH_TO_CHATS', { name: name, roomId: roomId, messages: [], path, myself, unreadMessages: 0, data: {}, unparsedMessages: [], unsubscribe: null, lastMessage: null, answerMessage : null, lastMessageWriten : null, version })
  },
  listenDataFromChatroom({ rootGetters, commit }, { roomId, callback = null }) {
    const firebase = rootGetters.getFirebase;
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      firebase.firestore().collection(chat.path).doc(roomId).onSnapshot((doc) => {
        if (doc.change !== "removed") {
          commit('UPDATE_DATA', { roomId: roomId, data: doc.data() });
          if (callback) {
            callback(doc.data());
          }
        }
      });
    }
  },
  listenInternalNotesFromChatroom({rootGetters, commit}, {roomId}){
    const firebase = rootGetters.getFirebase;
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      firebase.firestore().collection(chat.path).doc(roomId).collection('internalNotes').onSnapshot((snapshot) => {
        snapshot.docs.forEach((doc) => {
          if (doc.change !== "removed") {
            console.log(doc)
            commit('UPDATE_INTERNAL_NOTES', { roomId: roomId, internalNote: {...doc.data(), id: doc.id }});
          }
        })
      });
    }
  },
  async fetchLastMessageFromChatroom({ rootGetters, commit }, { roomId, callback = null }) {
    const firebase = rootGetters.getFirebase;
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      try {
        const querySnap = await firebase.firestore().collection(chat.path).doc(roomId).collection('messages').orderBy('date', 'desc').limit(1).get();
         
        const lastMessage = querySnap.docs[0] ? querySnap.docs[0] : null;
        const message = lastMessage ? parseMessageFromFirebase({...lastMessage.data(), id : lastMessage.id}) : null;
        commit("SET_LAST_MESSAGE", { roomId, message });

        if (callback) {
          callback(message)
        }

      } catch (error) {
        console.log(error)
      }
    }
  },
  fetchLastMessagesFromChatroomFromTimestamp({ rootGetters, commit }, { roomId, timestamp = new Date().getTime(), callback = null, resetState = false }) {
    const firebase = rootGetters.getFirebase;
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {

      if (resetState) {
        commit('RESET_MESSAGES_FROM_CHAT', roomId);
      }

      firebase.firestore().collection(chat.path).doc(roomId).collection('messages').where('date', '<', timestamp).orderBy("date", "desc").limit(15).get()
        .then((collection) => {

          if (callback) {
            const documents = collection.docs.map((doc) => {
              return parseMessageFromFirebase({...doc.data(), id : doc.id })
            })
            callback(documents);
          }

          collection.docs.forEach(async (doc) => {
            const tmpMessage = doc.data();
            if (tmpMessage.text !== '') {
              const answersSnap = await doc.ref.collection('answer').get()
              const answer = (answersSnap.size > 0 ) ? parseMessageFromFirebase(await answersSnap.docs.map((answer) => answer.data()).pop()) : null
              const message = {...parseMessageFromFirebase({...doc.data(), id : doc.id }), answer}
              commit('ADD_PREVIUS_MESSAGE_TO_CHAT', { message, roomId });
              commit('ADD_UNPARSED_MESSAGE_TO_CHAT', { roomId: roomId, unparsedMessage: {...doc.data(), id : doc.id} })


              // const lastMessage = chat.messages[0];
              // commit('SET_LAST_MESSAGE', { message: getLastMessage(chat.messages[chat.messages.length - 1]), roomId })
              // if (lastMessage) {
              //   if (hasToAddToPreviusMessage(lastMessage, message)) {
              //     lastMessage.text = message.text + "<br>" + lastMessage.text
              //   } else {
              //     commit('ADD_PREVIUS_MESSAGE_TO_CHAT', { message, roomId });
              //   }
              // } else {
              //   commit('ADD_PREVIUS_MESSAGE_TO_CHAT', { message, roomId });
              // }
            }
          });
        })
    }
  },
  fetchAllMessagesFromChatroom({ rootGetters, commit }, { roomId }) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      firebase.firestore().collection(chat.path).doc(roomId).collection('messages').orderBy("date", "desc").get()
        .then((collection) => {
          commit('RESET_MESSAGES_FROM_CHAT', roomId);
          collection.docs.forEach((doc) => {
            const tmpMessage = doc.data();
            if (tmpMessage.text !== '') {

              if (('isReaded' in tmpMessage) && !tmpMessage.isReaded && tmpMessage.from !== user.uid) {
                commit('SUM_UNREAD_MESSAGE_TO_CHAT', roomId);
              }

              const message = parseMessageFromFirebase({...doc.data(), id : doc.id })
              const tmplastMessage = (chat.messages[chat.messages.length - 1]) ? chat.messages[chat.messages.length - 1] : message
              commit('SET_LAST_MESSAGE', { message: getLastMessage(tmplastMessage), roomId })
              commit('ADD_PREVIUS_MESSAGE_TO_CHAT', { message, roomId })
              commit('ADD_UNPARSED_MESSAGE_TO_CHAT', { roomId: roomId, unparsedMessage: {...doc.data(), id : doc.id} })
              // const lastMessage = chat.messages[0]
              // if (lastMessage) {
              //   if (hasToAddToPreviusMessage(lastMessage, message)) {
              //     lastMessage.text = message.text + "<br>" + lastMessage.text
              //   } else {
              //     commit('ADD_PREVIUS_MESSAGE_TO_CHAT', { message, roomId });
              //   }
              // } else {
              //   commit('ADD_PREVIUS_MESSAGE_TO_CHAT', { message, roomId });
              // }
            }
          });
        });
      return true;
    }
  },
  stopListenMessagesFromChatroom({ state, commit }, { roomId }) {
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      if (chat.unsubscribe) {
        chat.unsubscribe();
        commit('UPDATE_UNSUBSCRIBE', { unsubscribe: null, roomId: chat.roomId });
      }
    }
  },
  async listenMessagesFromChatroom({ rootGetters, commit, dispatch }, { roomId, unreadMessagesCallback, newMessageCallback }) {
    const firebase = rootGetters.getFirebase;
    await dispatch("fetchLastMessageFromChatroom", { roomId });
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat && chat.unsubscribe === null) {

    firebase.firestore().collection(chat.path).doc(roomId).collection('messages').where('from', '==', 'system').onSnapshot((snapshot) => {
        for (const change of snapshot.docChanges()) {
          if (change.type === "modified") {
            const message = parseMessageFromFirebase({...change.doc.data(), id : change.doc.id });
            commit('UPDATE_MESSAGE', { message, roomId })
          }
        }        
      })

      const timestamp = !chat.lastMessage ? new Date().getTime() : chat.lastMessage.date

      const unsubscribe = firebase.firestore().collection(chat.path).doc(roomId).collection('messages').where('date', '>', timestamp).onSnapshot((snapshot) => {
        for (const change of snapshot.docChanges()) {

          if (change.type === "added") {
            const message = parseMessageFromFirebase({...change.doc.data(), id : change.doc.id });
            //const lastMessage = chat.messages[chat.messages.length - 1];

            commit('SET_LAST_MESSAGE', { message, roomId })
            commit('ADD_MESSAGE_TO_CHAT', { message, roomId });

            // if (lastMessage) {
            //   if (hasToAddToPreviusMessage(lastMessage, message)) {
            //     lastMessage.text = lastMessage.text + "<br>" + message.text
            //   } else {
            //     commit('ADD_MESSAGE_TO_CHAT', { message, roomId });
            //   }
            // } else {
            //   commit('ADD_MESSAGE_TO_CHAT', { message, roomId });
            // }

            if (newMessageCallback) {
              newMessageCallback(message);
            }

            if (chat.myself.id !== message.user.id) {
              if (unreadMessagesCallback) {
                unreadMessagesCallback(chat.unreadMessages)
              }
              commit('SUM_UNREAD_MESSAGE_TO_CHAT', roomId)
            }

            commit('ADD_UNPARSED_MESSAGE_TO_CHAT', { roomId: roomId, unparsedMessage: {...change.doc.data(), id : change.doc.id} })
          }
        }
      });
      commit('UPDATE_UNSUBSCRIBE', { unsubscribe: unsubscribe, roomId: chat.roomId });
      return unsubscribe;
    }
  },
  saveMessageFromChatroom({ rootGetters, commit }, { roomId, message }) {
    return new Promise((resolve) => {
      const firebase = rootGetters.getFirebase;
      const chat = state.chats.find(chat => chat.roomId === roomId);
      if (chat) {
        firebase.firestore().collection(chat.path).doc(roomId).collection('messages').add(message).then((doc) => {
          if (chat.answerMessage) {
            const answer = chat.unparsedMessages.find(msg => msg.id === chat.answerMessage.id)
            firebase.firestore().collection(chat.path).doc(roomId).collection('messages').doc(doc.id).collection('answer').add(answer)
            commit("SET_ANSWER_MESSAGE", { roomId, message : null }) 
          }
          resolve(doc.id)
        })
      }
    })
  },
  uploadFileToStorage({ rootGetters }, { file, callback }) {
    return new Promise((resolve) => {
      const storage = rootGetters.getFirebase.storage();
      const name = `${new Date().getTime()}_${file.name}`;
      const uploadTask = storage.ref(`files/${name}`).put(file);
      const uploading = function (snapshot) {
        callback((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      }
      uploadTask.on(`state_changed`, uploading, null, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          resolve(url);
        });
      });
    })
  },
  updateUnreadMessages({ commit }, roomId) {
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      commit('RESET_UNREAD_MESAGE_TO_CHAT', roomId)
    }
  },
  createNewChatroom({ rootGetters }, id) {
    return new Promise(function (resolve) {
      const user = rootGetters.getUser
      const firebase = rootGetters.getFirebase
      const room = new Date().getTime();
      const participants = [user.uid, id];
      firebase.firestore().collection("chats").doc(room + "-").set({ createdAt: new Date().getTime(), participants: [user.uid, id] }).then(() => {
        participants.forEach(uid => firebase.firestore().collection("chats").doc(room + "-").collection("participants").doc(uid).set({ uid }));
        resolve({ id: id, room: room, });
      })
    })
  },
  userIsWriting({ rootGetters }, {roomId, status = true}) {
    const user = rootGetters.getUser
		const firebase = rootGetters.getFirebase
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      firebase.firestore().collection(chat.path).doc(roomId).update({
        userIsWriting : { [user.uid] : (status) ? user.fullname : null }
      })
    }
  },
  sendSystemMessageToChatroom({rootGetters}, { roomId, data }) {
    return new Promise((resolve, reject) => {
      const firebase = rootGetters.getFirebase;
      const base = {
        from: 'system',
        date: new Date().getTime(),
      }
      firebase.firestore().collection("chats").doc(roomId).collection("messages").add({...base, ...data}).then((doc) => {
        return resolve(doc.id)
      }).catch((error) => {
        return reject(error)
      })
    })
	},
  setAnswerMessage({ commit }, { roomId, message }) {
    commit("SET_ANSWER_MESSAGE", { roomId, message })
  },
  listenToMessage({ rootGetters, commit } , { roomId, messageId}) {
    const firebase = rootGetters.getFirebase;
    const chat = state.chats.find(chat => chat.roomId === roomId);
    if (chat) {
      firebase.firestore().collection(chat.path).doc(roomId).collection('messages').doc(messageId).onSnapshot((doc) => {
        const message = parseMessageFromFirebase({...doc.data(), id : doc.id });
        commit('UPDATE_MESSAGE', { message, roomId })
      })
    }
  }
}


// function hasToAddToPreviusMessage(previusMessage, newMessage) {
//   if (newMessage.type === 'text') {
//     if (previusMessage.user.id === newMessage.user.id && previusMessage.type === newMessage.type) {
//       const messageDate = new Date(previusMessage.date)
//       const newMessageDate = new Date(newMessage.date);
//       if (messageDate.getMonth() == newMessageDate.getMonth() && messageDate.getDate() == newMessageDate.getDate() && messageDate.getFullYear() == newMessageDate.getFullYear()) {
//         if (messageDate.getHours() == newMessageDate.getHours() && messageDate.getMinutes() == newMessageDate.getMinutes()) {
//           return true;
//         }
//       }
//     }
//   }
//   return false;
// }

function getLastMessage(tmpMessage) {
  const message = { ...tmpMessage }

  if (/<br>/.test(message.text)) {
    message.text = message.text.split("<br>").pop()
  }

  return message
}




export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};