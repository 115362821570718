<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns"  :search="search" :filters="filters" :can-create="isAdmin" :can-delete="isAdmin" :can-edit="isAdmin">
      <template v-slot:action-buttons="scope">
        <el-dropdown-item icon="fas fa-images" @click.native="showChangePictureDialog = true; organization = scope.row">{{ $t('configuration.organization.change-picture') }}</el-dropdown-item>
        <el-dropdown-item @click.native="showChangePictureDialog = true; organization = scope.row; active = 'remove'" v-if="scope.row.logoAlt">
          <span class="fa-stack small">
            <i class="fas fa-images fa-stack-1x"></i>
            <i class="fas fa-ban fa-stack-2x" style="color:Tomato"></i>
          </span>
          <span>{{ $t('configuration.organization.remove-logo') }}</span>
        </el-dropdown-item>
        <el-dropdown-item icon="fas fa-tachometer-alt" @click.native="handleDashboard(scope.row)" v-if="isAdmin">{{ $t("configuration.organization.rewrite-dashboard")  }}</el-dropdown-item>
        <el-dropdown-item icon="fas fa-cog" @click.native="viewAdvanceConfig(scope.row)" v-if="isAdmin">{{ $t('configuration.organization.advance-configuration') }}</el-dropdown-item>
      </template>
    </the-crud>
    <el-dialog
      :visible.sync="showChangePictureDialog"
      :close-on-click-modal="!loading"
      :close-on-press-escape="!loading"
      :show-close="!loading"
      width="30%"
      @closed="active = 'change'"
      center>
      <div v-if="active === 'change'" class="dialog-container">
        <el-upload 
          drag action="" 
          :http-request="upload" 
          :limit="1" 
          :show-file-list="false" 
          :thumbnail-mode="true" 
          :disabled="loading" v-loading="loading"
          style="margin: 0 auto"
          accept=".jpg,.jpeg,.png"
          >
          <div>
            <img :src="url" v-if="organization && organization.logo" />
            <div class="el-upload__text" v-if="!loading">{{ $t("common.drop-your-file-here-or") }} <em>{{ $t("common.click-to-load") }}</em></div>
          </div>
        </el-upload>
        <p style="margin-bottom: 0" class="text-center" v-if="loading">{{ $t("common.uploading-image") }}</p>
      </div>
      <div v-if="active === 'remove'" class="dialog-container">
        <h2 class="text-center">{{ $t("configuration.organization.remove-logo") }}</h2>
        <img src="/images/hiperme_isologotipo.png" width="200" style="display: block; margin:  0 auto;"/>
        <p class="text-center" v-if="!loading">{{ $t("configuration.organization.this-image-will-be-used") }}</p>
        <p class="text-center" v-if="loading">{{ $t("configuration.organization.removing-logo") }}</p>
        <div class="dialog-container" v-if="!loading">
          <el-button class="button" type="danger" @click="remove">{{ $t("common.delete") }}</el-button>
          <el-button class="button" @click="showChangePictureDialog = false">{{ $t("common.cancel") }}</el-button>
        </div>
      </div>
      <div v-if="active === 'success'" class="dialog-container">
        <h2>{{ $t("configuration.organization.this-will-be-the-picture-of-the-organization") }}</h2>
        <img :src="url" />
        <p class="text-center">{{ $t("common.the-change-may-take-a-couple-of-days-to-apply") }}</p>
        <el-button class="button" type="primary" @click="showChangePictureDialog = false">{{ $t("common.understood") }}</el-button>
      </div>
      <div v-if="active === 'error'" class="dialog-container">
        <el-page-header @back="active = 'change'"/>
        <el-alert
          :closable="false"
          :title="logoErrors"
          type="error"
          effect="dark">
        </el-alert>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'
import { mapGetters } from 'vuex'
export default {
  components : {
    TheCrud
  },
  data() {
    return {
      title : this.$t('configuration.organization.organizations'),
      store : 'organizations',
      search : ['name'],
      columns : [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('configuration.organization.name') },
        { index : 'pbxDomainAlt',label : 'PBX'},
        { index : 'dataSetAlt', label : this.$t('configuration.organization.use_call_center'), type : 'boolean' },
        { index : 'LiveHelperChat', label : this.$t('configuration.organization.use_chat_center'), type : 'boolean' },
        { index : 'osticket', label : this.$t('configuration.organization.use_mail_center'), type : 'boolean'},
        { index : 'logoAlt', label : this.$t('configuration.organization.logo'), type : 'img'},
        { index : 'sessions', label : this.$t('configuration.organization.ammount-of-user-sessions'), 'hide in table' : true},
        { index : 'agentSessions', label : this.$t('configuration.organization.ammount-of-agent-sessions'), 'hide in table' : true},
      ],
      filters : [
        {
          type : 'boolean',
          index : 'LiveHelperChat[exists]',
          label : this.$t('configuration.organization.use_chat_center'),
          booleanType : 'string'
        },
        {
          type : 'boolean',
          index : 'osticket[exists]',
          label : this.$t('configuration.organization.use_mail_center'),
          booleanType : 'string'
        }
      ],
      showChangePictureDialog: false,
      organization: null,
      loading: false,
      active: "change",
      xhr: null,
      logoErrors : [],
    }
  },
  methods: {
    async upload(xhr){
      this.file = xhr.file
      this.loading = true
      try {
        await this.$store.dispatch("organizations/changeLogo", {
          organization: this.organization,
          file: this.file,
        })
        this.active = "success";
      } catch (err) {
        this.active = "error";
        this.logoErrors = err.response.data[0].message;
      } finally {
        this.loading = false;
      }
    },
    getLogoUrl(filename) {
      return process.env.VUE_APP_API_HOST.replace('/api', '/img/logo') + "/" + filename
    },
    viewAdvanceConfig(organization) {
      this.$store.commit("contactMe/SET_ORGANIZATION", organization);
      this.$store.commit("contactMe/SET_ORGANIZATION_ID", organization.id);
      this.$store.commit("contactMe/SET_ORGANIZATION_NAME", organization.name);
      this.$router.push({ name: "contact-me-config" })
    },
    async remove() {
      this.loading = true;
      try {
        await this.$store.dispatch("organizations/removeLogo", this.organization.id)
        this.organization.logo = "/images/hiperme_isologotipo.png";
        this.active = "success";
      } catch (err) {
        console.log(err)
        this.active = "error";
      } finally {
        this.loading = false;
      }
    },
    handleDashboard({id}) {
      this.$store.dispatch("restartAgentDashboard", id);
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    url() {
      return this.organization && this.organization.logo !== "/images/hiperme_isologotipo.png" ? this.getLogoUrl(this.organization.logo) : "/images/hiperme_isologotipo.png";
    },
    user() {
      return this.$store.getters["getUser"];
    },
  }
}
</script>

<style scoped>
  .dialog-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .dialog-container img {
    width: 60%;
    aspect-ratio: 3 / 2;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }

  .fa-stack.small { 
    font-size: 0.9em;
    margin-left: -10px;
  }
  i { 
    vertical-align: middle; 
  }

  .button + .button {
    margin-left: 0;
    margin-top: var(--column)
  }
</style>