<template>
	<Box id="clientInfoBox" :title="$t('agent-console.customer-information')">
		<ul class="mb-0 overflow">
			<TheClientInfoItem @sendEmail="sendEmail" @searchTicket="searchTicket" @call="call" v-for="(item, key) in clientInfoList" :key="key" :client-info-item="item" :activity-key="activityKey" :enabledEdit="crudEnabled"/> 
		</ul>
        <div v-if="crudEnabled">
            <div v-if="!addingValue">
                <el-button icon="fas fa-plus" size="small" type="text" @click="addingValue = true"> Add property</el-button>
            </div>
            <div class="form" v-else>
                <el-input v-model="property" size="small" placeholder="Property" :disabled="saving" style="width: 100%"></el-input>
                <el-input v-model="value" size="small" placeholder="Value" :disabled="saving" style="width: 100%"></el-input>
                <el-button type="primary" size="small" @click="save" v-loading="saving">Save</el-button>
                <el-button size="small" @click="addingValue = false; property = '';value = ''" :disabled="saving">Cancel</el-button>
            </div>
        </div>
	</Box>
</template>

<script>
import { mapGetters } from 'vuex';
import Box from './Box.vue';
import TheClientInfoItem from "@/components/agent-console/TheClientInfoItem";
export default {
    data() {
        return {
            addingValue: false,
            property: "",
            value: "",
            saving: false,
        }
    },
    methods : {
        ...mapGetters(['getActiveItem']),
        sendEmail(value) {
            this.$emit('sendEmail', value);
        },
        searchTicket(email) {
            this.$emit('searchTicket', email);
        }, 
        call(value) {
            this.$emit('call', value)
        },
        async save() {
            this.saving = true;
            const response = await this.$store.dispatch("doAddPropertyToClientInfo", {
                roomId: this.activityKey,
                payload: {
                    label: this.property,
                    value: this.value,
                },
            });
            const actionKey = response.key;
            this.$store.dispatch("agentConsoleChat/listenToChatEngineAction", { key: actionKey, callback: this.callback });
        },
        callback(payload) {
            if (payload.status) {
                if (payload.status) {
                    this.addingValue = false;
                }
                this.saving = payload.status === "success" ? false : true;
            }
        },
    },
    components : {
        Box,
        TheClientInfoItem
    },
    computed: {
        clientInfoList() 
        {
            return this.clientInfo.filter((item) => {
                if ('hidden' in item) {
                    return !item.hidden
                }
                return true
            })
        }
    },
    props : {
        clientInfo : {
            required : true,
            type: Array,
            default: (() => ([]))
        },
        activityKey:{
            required: false,
            type: String,
        },
        crudEnabled: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
}
</script>

<style scoped>
    h3 {
        font-size: 16px;
    }

    ul {
        list-style: none;
        margin-bottom: var(--column);
    }

    ul li:last-child{
        margin-bottom: 0;
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: calc(var(--column) / 2);
        border-top: 1px solid var(--blue-grey);
        padding-top: var(--column)
    }

    button {
        margin: 0!important;
    }

    ul + div {
        margin-top: var(--column); 
    }
</style>